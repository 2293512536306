import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { bookingDetail, getBusiness, getProfile } from '../../store/slices/userSlice';
import moment from 'moment';
import { Download } from 'lucide-react';
import { useTranslation } from 'react-i18next';


const BookingDetail = () => {
  const { t } = useTranslation();
  const profile = useSelector(getProfile)
  const business = useSelector(getBusiness)
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [detail, setDetail] = useState()



  console.log("===>", business)


  const getBookingDetail = async () => {
    try {

      const response = await dispatch(bookingDetail({ id: location?.state?._id })).unwrap();
      setDetail(response?.data[0])

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  console.log(detail)
  useEffect(() => {
    let mount = true;
    if (mount) {
      getBookingDetail();
    }
    return () => {
      mount = false;
    };
  }, [location.state])

  console.log(detail)
  return (
    <>
      <div className="sp-bookings-page pb-5 px-md-5 px-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb sp-breadcrumb">
            <li class="breadcrumb-item"><a>{t('menu')}</a></li>
            <li class="breadcrumb-item"><a onClick={() => navigate("/bookings")}>{t('bookings')}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{t('booking')} {t('detail')}</li>
          </ol>
        </nav>
        <h2 className="font-size-24 titilium-font fw-bold blue-color mb-4">{t('booking')} {t('detail')}</h2>
        <div className="row mb-4">
          <div className="col-md-3">
            <div className="d-flex gap-2">
              <i class="bi bi-geo-alt light-blue-color font-size-20"></i>
              <div>
                <p className="font-size-16 black-color mb-0">{t('location')}</p>
                <p className="font-size-18 light-blue-color fw-semibold mb-0">{detail?.space?.name}</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="d-flex gap-2">
              <i class="bi bi-book light-blue-color font-size-20"></i>
              <div>
                <p className="font-size-16 black-color mb-0">{t('booking')} {t('number')}</p>
                <p className="font-size-18 light-blue-color fw-semibold mb-0">{detail?.bookingNumber}</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="d-flex gap-2">
              <i class="bi bi-calendar-event light-blue-color font-size-20"></i>
              <div>
                <p className="font-size-16 black-color mb-0">{t('booking')} {t('date')}</p>
                <p className="font-size-18 light-blue-color fw-semibold mb-0">{moment(detail?.createdAt).format("MMMM Do YYYY")}</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="d-flex gap-2">
              <i class="bi bi-repeat light-blue-color font-size-20"></i>
              <div>
                <p className="font-size-16 black-color mb-0">{t('status')}</p>
                <p className="font-size-18 light-blue-color fw-semibold mb-0">{detail?.status}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table sp-table table-borderless">
            <thead>
              <tr>
                <th scope="col">{t('packages')}</th>
                <th className="text-center" scope="col">{t('quantity')}</th>
                <th className="text-center" scope="col">{t('deposit')}</th>
                <th className="text-center" scope="col">{t('price')}</th>
              </tr>
            </thead>
            <tbody>

              {

                detail?.carts?.map((data, index) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex gap-3 align-items-center">
                        <img alt="" src={`${process.env.REACT_APP_IMGURL}/${data.roomId ? data?.roomId?.image[0] : data.virtualOfficeId ? data?.virtualOfficeId?.image[0] : data?.deskCategoryId?.image[0]}`} className="sp-detail-image-list" />
                        <div>
                          <p className="font-size-20 titilium-font light-blue-color fw-bold mb-0">{data?.planData?.name}</p>
                          <p className="font-size-20 titilium-font black-color fw-bold mb-0">{data.roomId ? data?.roomId?.name : data?.deskCategoryId?.name}</p>
                          {detail?.startTime ? <p className="font-size-16 titilium-font black-color mb-0">{t('startTime')} <span className="light-blue-color">{detail?.startTime}</span></p> : <></>}
                          {detail?.endTime ? <p className="font-size-16 titilium-font black-color mb-0">{t('endTime')} <span className="light-blue-color">{detail?.endTime}</span></p> : <></>}
                          {detail?.bookingHours ? <p className="font-size-16 titilium-font black-color mb-0">{t('hour')} <span className="light-blue-color">{detail?.bookingHours}</span></p> : <></>}
     
                          <p className="font-size-16 titilium-font black-color mb-0">{t('startDate')}: <span className="light-blue-color">{moment(detail?.startDate).format("MMMM Do YYYY")}</span></p>
                          {detail?.endDate ? <p className="font-size-16 titilium-font black-color mb-0">{t('endDate')}: <span className="light-blue-color">{moment(detail?.endDate).format("MMMM Do YYYY")}</span></p> : <></>}
              
                        </div>
                      </div>
                    </td>
                    <td className="text-center"><p className="font-size-16 titilium-font black-color mb-0">{detail.bookingHours ? detail.bookingHours : data?.count ? data?.count : 1}</p></td>
                    <td className="text-center"><p className="font-size-16 titilium-font black-color mb-0">{business?.currency} {data?.planData?.deposit}</p></td>
                    <td className="text-center"><p className="font-size-16 titilium-font black-color mb-0">{business?.currency} {data?.pricing?.price}</p></td>
                  </tr>
                ))
              }


            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-end flex-column align-items-end pb-4 border-bottom mb-3">
          <p className="font-size-20 titilium-font black-color fw-bold mb-0 text-end">{t('subtotal')}: <span className="light-blue-color">{detail?.subtotal + detail?.depositAmount}</span> </p>
          <p className="font-size-14 titilium-font black-color mb-0">{t('deposit')} {business?.currency} {detail?.depositAmount} + {t('price')} {business?.currency} {detail?.subtotal}</p>
        </div>
        <div className="row mb-4">
          <div className="col-md-5 offset-md-7">
            <div className="row mb-2">
              <div className="col-6 align-self-center text-end">
                <p className="font-size-20 titilium-font black-color mb-0">{t('billing')} {t('detail')}</p>
              </div>
              <div className="col-6 text-end">
                <p className="font-size-20 titilium-font black-color mb-0">{detail?.carts[0].pricing?.type} {t('price')}</p>
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-6 align-self-center text-end">
                <p className="font-size-12 titilium-font black-color mb-0">{t('subtotal')}</p>
              </div>
              <div className="col-6 text-end">
                <p className="font-size-16 titilium-font black-color mb-0"><b>{detail?.subtotal} {business?.currency}</b></p>
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-6 align-self-center text-end">
                <p className="font-size-12 titilium-font black-color mb-0">{t('discount')}</p>
              </div>
              <div className="col-6 text-end">
                <p className="font-size-16 titilium-font black-color mb-0"><b>-{detail?.discount} {business?.currency}</b></p>
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-6 align-self-center text-end">
                <p className="font-size-12 titilium-font black-color mb-0">{t('total')}</p>
              </div>
              <div className="col-6 text-end">
                <p className="font-size-16 titilium-font black-color mb-0"><b>{detail?.subtotal - detail?.discount} {business?.currency}</b></p>
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-6 align-self-center text-end">
                <p className="font-size-12 titilium-font black-color mb-0">{t('tax')} {detail?.tax * 100}%</p>
              </div>
              <div className="col-6 text-end">
                <p className="font-size-16 titilium-font black-color mb-0"><b>{Math.round((detail?.subtotal - detail?.discount) * detail?.tax)} {business?.currency}</b></p>
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-6 align-self-center text-end">
                <p className="font-size-12 titilium-font black-color mb-0">{t('netTotal')}</p>
              </div>
              <div className="col-6 text-end">
                <p className="font-size-16 titilium-font black-color mb-0"><b>{(detail?.totalAmount)} {business?.currency}</b></p>
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-6 align-self-center text-end">
                <p className="font-size-12 titilium-font black-color mb-0">{t('deposit')}</p>
              </div>
              <div className="col-6 text-end">
                <p className="font-size-16 titilium-font black-color mb-0"><b>{detail?.depositAmount} {business?.currency}</b></p>
              </div>
            </div>
            <div className="row mb-3 border-top pt-1">
              <div className="col-6 align-self-center text-end">
                <p className="font-size-16 titilium-font black-color mb-0">{t('amount')} {t('due')}</p>
              </div>
              <div className="col-6 text-end">
                <p className="font-size-20 titilium-font light-blue-color mb-0"><b>{detail?.totalAmount + detail?.depositAmount} {business?.currency}</b></p>
              </div>
            </div>
          </div>
        </div>
        <div className="white-bg mb-3 sp-shadow sp-radius-20">
          <div class="table-responsive">
            <table class="table sp-table table-borderless">
              <thead>
                <tr>
                  <th scope="col">#{t('invoiceNo')}</th>
                  <th scope="col">{t('due')} {t('date')}</th>
                  <th scope="col">{t('amount')}</th>
                  <th scope="col">{t('PDF')}</th>
                </tr>
              </thead>
              <tbody>
                {detail?.invoices?.map((data, i) => (
                  <tr>
                    <td style={{ 'verticalAlign': 'middle' }}>{data?.invoiceNumber}</td>
                    <td style={{ 'verticalAlign': 'middle' }}>{moment(data?.dueDate).format("MMMM Do YYYY") }</td>
                    <td style={{ 'verticalAlign': 'middle' }}>{business?.currency} {data?.subtotal + data?.depositAmount}</td>
                    <td style={{ 'verticalAlign': 'middle' }}><a href={`${process.env.REACT_APP_IMGURL}/${data?.invoiceFile}`} target="_blank" download ><Download stroke="#3A5CD6" /></a></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

      </div>

    </>
  )
}

export default BookingDetail