

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { selectBusiness, signinUser, socialLogin } from "../../store/slices/userSlice";
import Cookies from "js-cookie";


const SelectBusiness = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();




    const [user_device_token, setuser_device_token] = useState(true)
    const [businessId, setbusinessId] = useState("")
    const [businessDetail, setbusinessDetail] = useState(location?.state?.business)


    console.log(location?.state.data._id)

    useEffect(() => {
        const data = Cookies.get("user_device_token");
        setuser_device_token(data);
    }, []);

    console.log(user_device_token, "user_device_token")

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let paylaod = {
                businessId,
                email: location?.state.data.email,
                devicetoken: user_device_token
            }
            const response = await dispatch(selectBusiness(paylaod)).unwrap();

            navigate("/")

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };



    console.log(businessId)

    return (
        <>
            <section class="authentication-bg">
                <div class="container py-5 h-100">
                    <div class="row d-flex justify-content-center align-items-center h-100">
                        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div class="card shadow-sm rounded border-0">
                                <div class="card-body p-5 text-center">
                                    <img onClick={() => navigate("/")} className="mx-auto d-block auth-logo mb-5 cursor-pointer" width="400" src={`${process.env.REACT_APP_FRONTURL}assets/images/logo-new.svg`} />

                                    <form onSubmit={handleSubmit}>


                                        {businessDetail?.length > 0 &&
                                            <div class="mb-3">
                                                <label class="blue-color fw-semibold">Business</label>
                                                <div className="d-flex gap-3 flex-wrap">
                                                    {businessDetail?.map((data, i) => (
                                                        <>
                                                            <input class="btn-check" type="radio" name="type" id={`type-${data?._id}`} key={i} onChange={() => setbusinessId(data._id)} />
                                                            <label class="btn sp-check-select" for={`type-${data?._id}`}>{data.name}</label>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>}



                                        <button class="btn sp-button sp-blue-button mb-4 w-100" type="submit">Select</button>

                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default SelectBusiness;
