import React, { useEffect, useState } from 'react'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deletePrintandScan, getBusiness, getMailAndPackage, getPrintAndScan, getProfile } from '../../store/slices/userSlice';
import moment from 'moment';
import { Download, Eye, Pencil, Search, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const PrintAndScan = () => {
    const { t } = useTranslation();
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const business = useSelector(getBusiness)
    const profile = useSelector(getProfile)
    const [load, setload] = useState(false);

    const [printsAndScans, setprintsAndScans] = useState()


    const mailPackageList = async () => {
        try {
            setload(true);
            const response = await dispatch(getPrintAndScan(business?.businessId)).unwrap();

            setprintsAndScans(response?.data)
            setload(false);


        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };
    const handleDelete = async (data) => {
        try {

           
            await dispatch(deletePrintandScan(data)).unwrap();
            mailPackageList();

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    console.log(printsAndScans)

    useEffect(() => {
        let mount = true;
        if (mount) {
            mailPackageList();
        }
        return () => {
            mount = false;
        };
    }, [business])

    return (
        <>
                <div className="sp-customer-page pb-5 px-md-5 px-3">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb sp-breadcrumb">
                    <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{t('printAndScan')}</li>
                </ol>
            </nav>

            <div className="row gy-3 mb-4 align-items-center">
                <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
                    <img height="32" src="../assets/images/print-scan-icon-sp.svg" className="icon-text" />
                    <h2 className="font-size-24 titilium-font fw-bold black-color mb-0">{t('printAndScan')}</h2>
                </div>
                <div className="col-md-6 order-md-2 order-3">
                </div>
                <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
                    <button class="btn sp-button sp-blue-button" onClick={() => navigate("/add-print-scan-request")}>{t('add')} {t('printAndScan')}</button>
                </div>
            </div>

            <div className="sp-shadow sp-radius-20 white-bg">
                <div class="table-responsive">
                    <table class="table sp-table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">{t('type')}</th>
                                <th scope="col">{t('location')}</th>
                                <th scope="col">{t('colored')}</th>
                                <th scope="col">{t('requestedAt')}</th>
                                <th scope="col">{t('assistedBy')}</th>
                                <th scope="col">{t('status')}</th>
                                <th scope="col">{t('file')}</th>
                                <th style={{ width: '100px' }} scope="col">{t('actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {load ? new Array(6).fill(null).map((_, index) => (<tr><td colSpan={8}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : printsAndScans?.map((data, i) => (
                                <tr className='cursor-pointer' >

                                    <td style={{ 'verticalAlign': 'middle' }}>{data?.type}</td>
                                    <td style={{ 'verticalAlign': 'middle' }}>{data?.spaceId?.name}</td>
                                    <td style={{ 'verticalAlign': 'middle' }}>{data?.isColor ? "Yes" : "No"}</td>
                                    <td style={{ 'verticalAlign': 'middle' }}>{moment(data?.createdAt).format("MMMM Do YYYY HH:mm")}</td>
                                    <td style={{ 'verticalAlign': 'middle' }}>{data?.employeeId ? data?.employeeId?.name : "Not Yet"}</td>
                                    <td style={{ 'verticalAlign': 'middle' }}><span className={`btn sp-button py-2 ${data?.isCompleted == 1 ? "sp-green-button" : "sp-red-button"}`} >{data?.isCompleted == 1 ? <>{t('completed')}</> : <>Not {t('completed')}</>}</span></td>
                                    <td style={{ 'verticalAlign': 'middle' }}>
                                        <div className="d-flex align-items-center gap-2">
                                            {data?.attachment.length > 0 ? <p className="font-size-16 black-color titilium-font mb-0 text-center"><a href={`${process.env.REACT_APP_IMGURL}/${data?.attachment[0]}`} target="_blank" download ><Download stroke="#3A5CD6" /></a></p> : "NAN"}
                                        </div>
                                    </td>
                                    <td style={{ 'verticalAlign': 'middle' }}>
                                        <div className="d-flex align-items-center gap-2">
                                        <div onClick={() => navigate("/print-and-scan-detail", { state: data })} className={`sp-action-icons`}><Eye width="18" /></div>
                                        {/* <div className={`sp-action-icons`}><Pencil width="18" /></div> */}
                                        <div  className={`sp-action-icons`}><X width="18" /></div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        </>
    )
}

export default PrintAndScan