import React, { useEffect, useState } from 'react'
import { Heart, ImageDown } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { addLikeAndUnlike, listOfPlans, getBookingList, getUsersToStartChat, chatList, getProfile, getBusiness, getNews, getEvents } from '../../store/slices/userSlice';
import { getSocket } from '../../store/slices/socketSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import CardSkeleton from '../../components/CardSkeleton';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const business = useSelector(getBusiness)
    const profile = useSelector(getProfile)


    const [plans, setplans] = useState()
    const [allNews, setallNews] = useState()
    const [allEvents, setallEvents] = useState()
    const [filterExistingChats, setfilterExistingChats] = useState();
    const [booking, setbooking] = useState()
    const [filterType, setfilterType] = useState("upcoming")
    const [load, setload] = useState(false);
    const [load1, setload1] = useState(false);
    const [load2, setload2] = useState(false);
    const [load3, setload3] = useState(false);
    const [load4, setload4] = useState(false);


    const getAllPlans = async () => {
        try {
            setload(true);
            const response = await dispatch(listOfPlans(business?.businessId)).unwrap();
            setplans(response?.data)
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };

    const getAllNews = async () => {
        try {
            setload1(true);
            const response = await dispatch(getNews(business?.businessId)).unwrap();
            setallNews(response?.data)
            setload1(false);
        } catch (rejectedValueOrSerializedError) {
            setload1(false);
            console.log(rejectedValueOrSerializedError);
        }
    };

    const getAllEvents = async () => {
        try {
            setload2(true);
            const response = await dispatch(getEvents(business?.businessId)).unwrap();
            setallEvents(response?.data)
            setload2(false);
        } catch (rejectedValueOrSerializedError) {
            setload2(false);
            console.log(rejectedValueOrSerializedError);
        }
    };

    const likeUnLikePlan = async (data) => {
        try {
            let payload = {
                planId: data?._id,
                behave: data?.behave
            }
            await dispatch(addLikeAndUnlike(payload)).unwrap();
            getAllPlans()
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        let mount = true;
        if (mount) {
            getAllPlans();
            getAllNews()
            getAllEvents()
        }
        return () => {
            mount = false;
        };
    }, [business])

    const getAllChats = async () => {
        try {
            setload3(true);
            const response = await dispatch(chatList()).unwrap();

            setfilterExistingChats(response?.data)
            setload3(false);
        } catch (rejectedValueOrSerializedError) {
            setload3(false);
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        let mount = true;
        if (mount) {
            getAllChats();

        }
        return () => {
            mount = false;
        };
    }, [])

    const BookByService = [
        {
            id: 1,
            name: 'Virtual Office',
            image: 'Virtual_Office_icon.png'
        },
        {
            id: 2,
            name: 'Hot Desk',
            image: 'Hot_Desk_icon.png'
        },
        {
            id: 3,
            name: 'Dedicated Desk',
            image: 'Dedicated_Desk_icon.png'
        },
        {
            id: 4,
            name: 'Executive Cabin',
            image: 'Executive_Cabin_icon.png'
        },
        {
            id: 5,
            name: 'Shared Room',
            image: 'Shared_Room_icon.png'
        },
        {
            id: 6,
            name: 'Private Room',
            image: 'Private_Room_icon.png'
        },
        {
            id: 7,
            name: 'Meeting Room',
            image: 'Meeting_Room_icon.png'
        },
        {
            id: 8,
            name: 'Podcast Room',
            image: 'Podcast_Room_icon.png'
        },
        {
            id: 9,
            name: 'Huddle Room',
            image: 'Huddle_Room-icon.png'
        },
        {
            id: 10,
            name: 'Event Space',
            image: 'Event_Space_icon.png'
        },
  
    ]

    const bookingList = async () => {
        try {
            setload4(true);
            const response = await dispatch(getBookingList({ filterType })).unwrap();
            setbooking(response?.data)
            setload4(false);
        } catch (rejectedValueOrSerializedError) {
            setload4(false);
            console.log(rejectedValueOrSerializedError);
        }
    };


    useEffect(() => {
        let mount = true;
        if (mount) {
            bookingList();
        }
        return () => {
            mount = false;
        };
    }, [filterType])
    return (
        <>
        <div className="sp-dashboard-page pb-5 px-md-5 px-3">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb sp-breadcrumb">
                    <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{t('home')}</li>
                </ol>
            </nav>
            <div className="row">
            <div className="col-md-8">
                <h2 className="font-size-24 titilium-font fw-bold blue-color mb-3">{t('bookNow')}</h2>
                <div className="sp-book-now">
                    <div className="sp-shadow sp-radius-20 white-bg p-3 mb-4">
                    <p className="font-size-18 titilium-font black-color mb-2">{t('byService')}</p>
                    <div className="row gy-3 mb-4">
                        {BookByService?.map((data, i) => (
                            <div onClick={() => navigate("/locations", { state: data?.name })} key={i} className="col-md-2 d-flex flex-column align-items-center justify-content-between gap-2 cursor-pointer">
                                <img className="" height="45" alt={data?.name} src={`../assets/images/${data?.image}`} />
                                <p className="font-size-12 black-color mb-0">{data.name}</p>
                            </div>
                        ))}
                    </div>
                    <p className="font-size-18 titilium-font black-color mb-3">{t('byLocation')}</p>
                    <div className="row gy-4">
                        {load ? new Array(6).fill(null).map((_, index) => (<CardSkeleton key={index} column="col-md-4" />)) : plans?.slice(0, 6)?.map((data, index) => (
                            <div className="col-md-4 sp-cards" key={index} >
                                <div className="sp-card-body">
                                    <img alt="" src={`${process.env.REACT_APP_IMGURL}/${data?.image[0]}`} className="sp-card-image w-100 cursor-pointer" onClick={() => navigate("/plan-detail", { state: data })} />
                                    <div className="d-flex justify-content-between align-items-center top-box">
                                        <span className="font-size-14 py-1 px-3 text-capitalize white-color fw-medium" style={{ backgroundColor: 'var(--color5)' }}>{data?.type}</span>
                                        {data?.isLiked ? <Heart className="cursor-pointer" fill="var(--color5" stroke="#FFFFFF" onClick={() => likeUnLikePlan(data)} /> : <Heart className="cursor-pointer" fill="#3131314D" stroke="#ffffff" onClick={() => likeUnLikePlan(data)} />}
                                    </div>
                                </div>
                                <div className="sp-card-footer cursor-pointer" onClick={() => navigate("/plan-detail", { state: data })}>
                                    <p className="font-size-16 black-color mb-0 titilium-font">{data?.spaceDetail?.name}</p>
                                    <p className="font-size-18 light-blue-color mb-0 fw-semibold">{data?.name}</p>
                                    {/* <div className="d-flex justify-content-between align-items-center">
                                        <p className="font-size-14 black-color mb-0 fw-semibold">Monthly Rs.3,000</p>
                                        <p className="font-size-14 grey-color mb-0"> Rs {data?.planDetail?.deposit}</p>
                                    </div> */}
                                </div>
                            </div>
                        ))}
                    </div>
                    </div>
                    
                {allNews && <>
                <h2 className="font-size-24 titilium-font fw-bold blue-color mb-3">{t('newsAndUpdates')}</h2>      
                <div className="sp-shadow sp-radius-20 white-bg p-3 mb-4">
                    <div className="row gy-3">
                        {load1 ? new Array(3).fill(null).map((_, index) => (
                        <div className="col-md-12" key={index}>
                            <div className="d-flex align-items-center gap-2 sp-news-update cursor-pointer">
                            <img className="shimmer" alt='' />
                            <div className="flex-shrink-1 w-100">
                            <div className="shimmer mb-1" style={{height:'26px', width:'200px'}}></div>
                                <div className="shimmer mb-1" style={{height:'16px', width:'100%'}}></div>
                                <div className="d-flex align-items-center gap-3 w-100">
                                <div className="shimmer" style={{height:'14px', width:'100px'}}></div>
                                <div className="shimmer" style={{height:'14px', width:'100px'}}></div>
                                </div>
                            </div>
                        </div>
                        </div>)) : allNews?.slice(0, 3)?.map((data, index) => (
                                <div className="col-md-12" key={index} onClick={() => navigate("/news-detail", { state: data })}>
                                    <div className="d-flex align-items-center gap-2 sp-news-update cursor-pointer">
                                        <img src={`${process.env.REACT_APP_IMGURL}/${data?.attachment[0]}`} alt='' />
                                        <div className="flex-shrink-1">
                                            <p className="font-size-26 titilium-font black-color mb-0">{data?.name}</p>
                                            <p className="font-size-16 grey-color mb-0">{data?.description}</p>
                                            <div className="d-flex align-items-center gap-3">
                                                <p className="font-size-16 titilium-font light-blue-color mb-0">{moment(data.createdAt).format("MMMM Do YYYY • h:mm a")}</p>
                                                <p className="font-size-16 titilium-font light-blue-color mb-0"><i class="bi bi-hand-thumbs-up"></i> {data?.totalLikes}</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                </>}

                </div>
            </div>
                        <div className="col-md-4">

                            <h2 className="font-size-24 titilium-font fw-bold blue-color mb-3">{t('inbox')}</h2>
                            <div className="sp-shadow sp-radius-20 white-bg p-3 mb-4">
                                <div className="sp-messages-list-container d-flex flex-column gap-2">
                                    {load3 ? new Array(3).fill(null).map((_, index) => (
                                    <div class="sp-message-user-list d-flex align-items-center gap-2 cursor-pointer sp-chat-enqiries sp-chat-enqiries-dashboard mb-1" key={index}>
                                    <div className="shimmer rounded-circle sp-user-image" ></div>
                                    <div class="flex-grow-1">
                                        <div className="d-flex align-items-center justify-content-between mb-1">
                                        <div className="shimmer" style={{height:'14px', width:'150px'}}></div>
                                        <div className="shimmer" style={{height:'12px', width:'80px'}}></div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="shimmer" style={{height:'14px', width:'250px'}}></div>
                                            <div className="shimmer sp-message-count rounded-circle"></div>
                                        </div>
                                    </div>
                                </div>)) :filterExistingChats?.slice(0, 4)?.map((data, index) => (
                                        <div class="sp-message-user-list d-flex align-items-center gap-2 cursor-pointer sp-chat-enqiries sp-chat-enqiries-dashboard" onClick={() => navigate("/messages", { state: data })} key={index}>
                                            {profile?._id == data?.sender_id?._id ? < img src={`${process.env.REACT_APP_IMGURL}/${data?.receiver_id?.imageName}`} class="rounded-circle sp-user-image" /> : < img src={`${process.env.REACT_APP_IMGURL}/${data?.sender_id?.imageName}`} class="rounded-circle sp-user-image" />}
                                            <div class="flex-grow-1">
                                                <div className="d-flex align-items-center justify-content-between mb-0">
                                                    <p className="font-size-14 light-blue-color titilium-font mb-0">{profile._id == data.sender_id._id ? data?.receiver_id?.name : data?.sender_id?.name}</p>
                                                    <p className="font-size-12 color-grey mb-0">{data?.last_message_date_time}</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <p className="font-size-14 titilium-font black-color mb-0">{data.message ? (data?.message?.length > 10 ? `${data?.message?.substring(0, 44)}...` : data?.message) : <ImageDown />}</p>
                                                    {data?.noOfUnread == 0 ? <></> : <span className="sp-message-count light-blue-bg white-color rounded-circle font-size-12">{data?.noOfUnread}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
              

                     
                            <h2 className="font-size-24 titilium-font fw-bold blue-color mb-3">{t('upcoming')} {t('events')}</h2>    
                            <div className="sp-shadow sp-radius-20 white-bg p-3 mb-4">
                                <div className="row gy-3">
                                    {load2 ? new Array(3).fill(null).map((_, index) => (
                                    <div className="col-md-12" key={index}>
                                        <div className="d-flex align-items-center gap-2 sp-upcoming-events cursor-pointer">
                                        <img className="shimmer" alt='' />
                                        <div className="flex-shrink-1 w-100">
                                        <div className="shimmer mb-1" style={{height:'14px', width:'150px'}}></div>
                                        <div className="shimmer mb-1" style={{height:'18px', width:'200px'}}></div>
                                        <div className="shimmer" style={{height:'14px', width:'250px'}}></div>
                                        </div>
                                    </div>
                                    </div>)) :allEvents?.map((data, index) => (

                                            <div className="col-md-12" key={index} onClick={() => navigate("/event-detail", { state: data })}>
                                                <div className="d-flex align-items-center gap-2 sp-upcoming-events cursor-pointer">
                                                    <img src={`${process.env.REACT_APP_IMGURL}/${data?.attachment[0]}`} alt='' />
                                                    <div className="flex-shrink-1">
                                                        <p className="font-size-14 titilium-font light-blue-color mb-0">{moment(data?.startTime).format("MMMM Do YYYY • h:mm a")}</p>
                                                        <p className="font-size-18 titilium-font black-color mb-1 fw-bold">{data?.name}</p>
                                                        <p className="font-size-14 grey-color mb-0"><i class="bi bi-geo-alt-fill"></i> {data?.location?.address}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        
                               
                           
                            <h2 className="font-size-24 titilium-font fw-bold blue-color mb-3">{t('upcoming')} {t('bookings')}</h2> 
                            <div className="sp-shadow sp-radius-20 white-bg p-3">
                                {load4 ? new Array(3).fill(null).map((_, index) => (
                                <div className="border-bottom pb-3 border-top cursor-pointer" key={index}>
                                <div className="d-flex justify-content-between  pt-3 mb-3">
                                    <div className="shimmer" style={{height:'14px', width:'200px'}}></div>
                                    <div className="shimmer" style={{height:'14px', width:'200px'}}></div>
                                </div>
                                    <div className="d-flex gap-3" key={index}>
                                        <div className="shimmer sp-detail-image-list"></div>
                                        <div>
                                        <div className="shimmer mb-1" style={{height:'20px', width:'150px'}}></div>
                                        <div className="shimmer mb-1" style={{height:'16px', width:'200px'}}></div>
                                        <div className="shimmer mb-1" style={{height:'16px', width:'180px'}}></div>
                                        <div className="shimmer mb-1" style={{height:'16px', width:'120px'}}></div>
                                        </div>
                                    </div>
                                    </div>)) : booking?.slice(0, 3).map((b, i) => (
                                    <div className="border-bottom pb-3 border-top cursor-pointer" key={i} onClick={() => navigate("/booking-detail", { state: b })}>
                                        <div className="d-flex justify-content-between  pt-3 mb-3">
                                            <p className="font-size-14 titilium-font black-color fw-bold mb-0">{t('booking')} {t('number')}: <span className="light-blue-color">{b.bookingNumber}</span> </p>
                                            <p className="font-size-14 titilium-font black-color fw-bold mb-0">{t('booking')} {t('date')}: <span className="light-blue-color">{moment(b.startDate).format("MMMM Do YYYY")}</span> </p>
                                        </div>
                                        {b?.carts?.map((data, index) => (
                                            <div className="d-flex gap-3" key={index}>
                                                <img alt="" src={`${process.env.REACT_APP_IMGURL}/${data.roomId ? data?.roomId?.image[0] : data.virtualOfficeId ? data?.virtualOfficeId?.image[0] : data?.deskCategoryId?.image[0]}`} className="sp-detail-image-list" />
                                                <div>
                                                    <p className="font-size-20 titilium-font black-color fw-bold mb-0">{data?.planData?.name}</p>
                                                    {/* <p className="font-size-16 titilium-font black-color mb-0">Duration <span className="light-blue-color">3 hours</span></p> */}
                                                    <p className="font-size-16 titilium-font black-color mb-0">{t('name')} <span className="light-blue-color">{data?.roomId ? data?.roomId?.name : data?.deskCategoryId?.name}</span></p>
                                                    <p className="font-size-16 titilium-font black-color mb-0">{t('type')} <span className="light-blue-color"> {data?.roomId ? data?.roomId?.type : data?.deskCategoryId?.type}</span></p>
                                                    <p className="font-size-16 titilium-font black-color mb-0">{t('quantity')} <span className="light-blue-color">{data?.count ? data?.count : 1}</span></p>
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </div>
                                ))}
                            </div>
                        
                                
                        </div>
                    </div>
        </div>
        </>
    )
}

export default Dashboard