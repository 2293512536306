import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { updateVisitingStatus } from '../../store/slices/userSlice';
import { useDispatch } from 'react-redux';


const VisitingPage = () => {
    const location = useLocation();
    const parts = location.pathname.split('/');

    const userId = parts[2];
    console.log(userId)

    const dispatch = useDispatch()

    const updateStatus = async () => {
        try {


            await dispatch(updateVisitingStatus(userId)).unwrap();


        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };



    useEffect(() => {

        updateStatus();

    }, [])

    return (
        <>

            <section className=" ">
                Spaces Manager Invitation Page
            </section >
        </>
    )
}

export default VisitingPage