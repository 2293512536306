import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { changePackageStatus } from '../../store/slices/userSlice';
import { Download } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const PrintScanDetail = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()

    const [detail, setdetail] = useState(location?.state);
    const [userStatus, setuserStatus] = useState("");
    const [pickTimeAndDate, setpickTimeAndDate] = useState("");


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let payload = {
                id: detail._id,
                pickUpDateTime: pickTimeAndDate,
                userStatus: userStatus

            }

            await dispatch(changePackageStatus(payload)).unwrap();
            navigate("/mail-and-packages")



        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

 console.log(detail, "detail");

    return (
        <>
        {detail.type=="Scan" && 
            <div className="sp-technical-support-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item"><a>{t('printAndScan')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('scan')} {t('detail')}</li>
                    </ol>
                </nav>

                <div className="row gy-3 mb-4 align-items-center">
                <div className="col-md-3 d-flex align-items-center gap-2">
                    <img height="32" src="../assets/images/print-scan-icon-sp.svg" className="icon-text" />
                    <h2 className="font-size-24 titilium-font fw-bold black-color mb-0">{t('scan')} {t('detail')}</h2>
                </div>
                    <div className="col-md-6 order-md-2 order-3">
                    </div>
                    <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
                        <button class="btn sp-button sp-blue-button" onClick={() => navigate("/add-print-scan-request")}>{t('add')} {t('printAndScan')}</button>
                    </div>
                </div>

                <div className="sp-shadow sp-radius-20 white-bg p-4">
                    <div className="row mb-3">
                        <div className="col-md-3">
                        <div className="d-flex gap-2">
                            <img width={36} src="../assets/images/requested-icon-sp.svg" alt="" />
                            <div>
                                <p className="font-size-18 color-black fw-semibold mb-0" style={{lineHeight:1}}>{t('requestedAt')}</p>
                                <p className="font-size-16 light-blue-color mb-0">{moment(detail?.createdAt).format("MMMM Do YYYY h:mm a")}</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-3">
                        <div className="d-flex gap-2">
                            <img width={36} src="../assets/images/assisted-by-icon-sp.svg" alt="" />
                            <div>
                                <p className="font-size-18 color-black fw-semibold mb-0" style={{lineHeight:1}}>{t('assistedBy')}</p>
                                <p className="font-size-16 light-blue-color mb-0">{detail?.employeeId  ?detail?.employeeId?.name:"Not Yet"}</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-3">
                        <a href={`${process.env.REACT_APP_IMGURL}/${detail?.attachment[0]}`} target="_blank" download >
                            <div className="d-flex gap-2">
                            <img width={36} src="../assets/images/file-download-icon-sp.svg" alt="" />
                            <div>
                                <p className="font-size-18 color-black fw-semibold mb-0" style={{lineHeight:1}}>{t('file')}</p>
                                {detail?.attachment.length > 0 ? <></> : <p className="font-size-16 light-blue-color mb-0">No File Yet</p>}
                            </div>
                        </div>
                        </a>
                        </div>
                        <div className="col-md-3">
                        <div className="d-flex gap-2">
                            <img width={36} src="../assets/images/requested-icon-sp.svg" alt="" />
                            <div>
                                <p className="font-size-18 color-black fw-semibold mb-0" style={{lineHeight:1}}>{t('status')}</p>
                                <p className="font-size-16 light-blue-color mb-0">{detail?.isCompleted ? "Completed" : "No Completed"}</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div>
                    <div class="form-floating sp-textarea">
                        <textarea class="form-control" name="description" style={{ height: '200px' }} disabled placeholder="Description" defaultValue={detail?.description}></textarea>
                        <label for="floatingInput">{t('description')}</label>
                    </div>
                    </div>
                </div>
            </div>}

            {detail.type=="Print" && 
            <div className="sp-technical-support-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item"><a>{t('printAndScan')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('print')} {t('detail')}</li>
                    </ol>
                </nav>

                <div className="row gy-3 mb-4 align-items-center">
                <div className="col-md-3 d-flex align-items-center gap-2">
                    <img height="32" src="../assets/images/print-scan-icon-sp.svg" className="icon-text" />
                    <h2 className="font-size-24 titilium-font fw-bold black-color mb-0">{t('print')} {t('detail')}</h2>
                </div>
                    <div className="col-md-6 order-md-2 order-3">
                    </div>
                    <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
                        <button class="btn sp-button sp-blue-button" onClick={() => navigate("/add-print-scan-request")}>{t('add')} {t('printAndScan')}</button>
                    </div>
                </div>

                <div className="sp-shadow sp-radius-20 white-bg p-4">
                    <div className="row gy-3 mb-3">
                        <div className="col-md-3">
                        <div className="d-flex gap-2">
                            <img width={36} src="../assets/images/requested-icon-sp.svg" alt="" />
                            <div>
                                <p className="font-size-18 color-black fw-semibold mb-0" style={{lineHeight:1}}>{t('requestedAt')}</p>
                                <p className="font-size-16 light-blue-color mb-0">{moment(detail?.createdAt).format("MMMM Do YYYY h:mm a")}</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-3">
                        <div className="d-flex gap-2">
                            <img width={36} src="../assets/images/assisted-by-icon-sp.svg" alt="" />
                            <div>
                                <p className="font-size-18 color-black fw-semibold mb-0" style={{lineHeight:1}}>{t('assistedBy')}</p>
                                <p className="font-size-16 light-blue-color mb-0">{detail?.employeeId  ?detail?.employeeId?.name:"Not Yet"}</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-3">
                        <div className="d-flex gap-2">
                            <img width={36} src="../assets/images/color-icon-sp.svg" alt="" />
                            <div>
                                <p className="font-size-18 color-black fw-semibold mb-0" style={{lineHeight:1}}>{t('color')}</p>
                                <p className="font-size-16 light-blue-color mb-0">{detail?.isColor == 0 ? "B&W" : "Color"}</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-3">
                        <div className="d-flex gap-2">
                            <img width={36} src="../assets/images/no-of-copies-icon-sp.svg" alt="" />
                            <div>
                                <p className="font-size-18 color-black fw-semibold mb-0" style={{lineHeight:1}}>{t('numberofCopies')}</p>
                                <p className="font-size-16 light-blue-color mb-0">{detail?.noOfPages}</p>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-3">
                        <a href={`${process.env.REACT_APP_IMGURL}/${detail?.attachment[0]}`} target="_blank" download >
                            <div className="d-flex gap-2">
                            <img width={36} src="../assets/images/file-download-icon-sp.svg" alt="" />
                            <div>
                                <p className="font-size-18 color-black fw-semibold mb-0" style={{lineHeight:1}}>{t('file')}</p>
                                {detail?.attachment.length > 0 ? <></> : <p className="font-size-16 light-blue-color mb-0">No File Yet</p>}
                            </div>
                        </div>
                        </a>
                        </div>
                        <div className="col-md-3">
                        <div className="d-flex gap-2">
                            <img width={36} src="../assets/images/requested-icon-sp.svg" alt="" />
                            <div>
                                <p className="font-size-18 color-black fw-semibold mb-0" style={{lineHeight:1}}>{t('status')}</p>
                                <p className="font-size-16 light-blue-color mb-0">{detail?.isCompleted ? "Completed" : "No Completed"}</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div>
                    <div class="form-floating sp-textarea">
                        <textarea class="form-control" name="description" style={{ height: '200px' }} disabled placeholder="Description" defaultValue={detail?.description}></textarea>
                        <label for="floatingInput">{t('description')}</label>
                    </div>
                    </div>
                </div>
            </div>}

        </>
    )
}

export default PrintScanDetail