import {
    createSlice,
    createAsyncThunk,
} from "@reduxjs/toolkit";
import axios from "axios";

import { extraReducers } from "../reducer/userReducer"

axios.defaults.baseURL = process.env.REACT_APP_APIURL
const user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : null
const business = JSON.parse(localStorage.getItem('business')) ? JSON.parse(localStorage.getItem('business')) : null
axios.defaults.headers.common['Authorization'] = user?.token;
const initialState = {
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    user: user,
    business: business,

    TcPp: null,
    dashboard: null,
    lineChart: null,
    areaChart: null,
    memes: null,

}





export const listOfPlans = createAsyncThunk('/member/get-all-plans', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/member/get-all-plans/${bodyData}`,)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getPlanDetail = createAsyncThunk('/member/plan-detail', async ({ id, type }, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/member/plan-detail/${id}/${type}`, null)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const signinUser = createAsyncThunk('/member/login', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/member/login`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const selectBusiness = createAsyncThunk('/member/select-business-for-member', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/member/select-business-for-member`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


export const verifyAccount = createAsyncThunk("/member/verify-account", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/member/verify-account`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const signUp = createAsyncThunk('/member/sign-up', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/member/sign-up`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const socialLogin = createAsyncThunk("/social-login", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/social-login`, bodyData);

        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const forgetPassword = createAsyncThunk('/member/forget-password', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/member/forget-password`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const changePassword = createAsyncThunk("/member/change-password", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/member/change-password`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const addBusiness = createAsyncThunk("/add-business", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/add-business`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const addSpace = createAsyncThunk("/add-space", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/add-space`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const addBooking = createAsyncThunk("/booking", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/booking`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const memberBooking = createAsyncThunk("member/member-booking", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`member/member-booking`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const addRoom = createAsyncThunk("/add-room", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/add-room`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const addDesk = createAsyncThunk("/add-desk", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/add-desk`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const addLead = createAsyncThunk("/add-lead", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/add-lead`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const downloadPdfSlice = createAsyncThunk("/download-invoice", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/download-invoice`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const invoicePayment = createAsyncThunk("/invoice-payment", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/invoice-payment`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const addPlan = createAsyncThunk("/add-plan", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/add-plan`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const getBusinessType = createAsyncThunk("/all-business-type", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`/all-business-type`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const getPlans = createAsyncThunk("get-plans-for-rooms-and-desk", async ({ spaceId, type }, { rejectWithValue }) => {
    try {

        const response = await axios.get(`get-plans-for-rooms-and-desk/${spaceId}/${type}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const getBookings = createAsyncThunk("booking-list", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`booking-list/${bodyData}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const getInvoices = createAsyncThunk("invoice-list", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`invoice-list/${bodyData}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const getBookingDetail = createAsyncThunk("booking-detail", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`booking-detail/${bodyData}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const getBookingList = createAsyncThunk("member/list-of-bookings", async (bodyData, { rejectWithValue }) => {
    try {
        console.log(bodyData.filterType)
        const response = await axios.get(`member/list-of-bookings/${bodyData?.filterType}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const checkDeskAvaibility = createAsyncThunk("member/check-desk-avaibility", async (bodyData, { rejectWithValue }) => {
    try {
        console.log(bodyData.filterType)
        const response = await axios.post(`member/check-desk-avaibility`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const checkHotDeskAvaibility = createAsyncThunk("member/check-avaibility-for-hot-desk", async (bodyData, { rejectWithValue }) => {
    try {
        console.log(bodyData.filterType)
        const response = await axios.post(`member/check-avaibility-for-hot-desk`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const profileSetting = createAsyncThunk("member/profile-setting", async (bodyData, { rejectWithValue }) => {
    try {
        console.log(bodyData.filterType)
        const response = await axios.post(`member/profile-setting`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const getMailAndPackage = createAsyncThunk('/member/get-member-mail-and-package', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/member/get-member-mail-and-package/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


export const userDetailData = createAsyncThunk("member/user-detail-for-edit", async (bodyData, { rejectWithValue }) => {
    try {
        console.log(bodyData.filterType)
        const response = await axios.get(`member/user-detail-for-edit/${bodyData}`,);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const getAllTechnicalCategory = createAsyncThunk('member/get-technical-category', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`member/get-technical-category/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const checkLocations = createAsyncThunk('member/check-location', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`member/check-location/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const addCommentOnTicket = createAsyncThunk('member/add-comments-on-ticket', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`member/add-comments-on-ticket`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const changePackageStatus = createAsyncThunk('member/change-mail-package-status', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`member/change-mail-package-status`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const closeTicket = createAsyncThunk('member/closed-ticket', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`member/closed-ticket/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const reopenTicket = createAsyncThunk('member/reopen-ticket', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`member/reopen-ticket/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const ticketDetail = createAsyncThunk('member/get-ticket-detail', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`member/get-ticket-detail/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getMemberTickets = createAsyncThunk('member/get-members-tickets', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`member/get-members-tickets/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getNotifications = createAsyncThunk('/get-notifications', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/get-notifications`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


export const getNotificationDetail = createAsyncThunk('/notifications-detail', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/notifications-detail`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const deleteNotification = createAsyncThunk('/delete-notification', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/delete-notification/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})



export const getPrintAndScan = createAsyncThunk('member/list-of-print-and-scan', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`member/list-of-print-and-scan/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const addTicket = createAsyncThunk('member/add-ticket-by-member', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`member/add-ticket-by-member`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const addPrintScanRequest = createAsyncThunk('member/create-print-and-scan-request', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`member/create-print-and-scan-request`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const availableSlots = createAsyncThunk("member/get-available-slots", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`member/get-available-slots`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const getDeskCountSlice = createAsyncThunk("member/get-count-for-desks", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`member/get-count-for-desks/${bodyData}` );
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const setupPassword = createAsyncThunk("member/setup-password", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`member/setup-password`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const bookingDetail = createAsyncThunk("member/booking-detail", async (bodyData, { rejectWithValue }) => {
    try {
        console.log(bodyData.id)
        const response = await axios.get(`member/booking-detail/${bodyData?.id}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const getNews = createAsyncThunk("member/find-news", async (bodyData, { rejectWithValue }) => {
    try {
        console.log(bodyData.id)
        const response = await axios.get(`member/find-news/${bodyData}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const getNewsDetail = createAsyncThunk("member/news-detail", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`member/news-detail/${bodyData}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const getEventDetail = createAsyncThunk("member/event-detail", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`member/event-detail/${bodyData}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);


export const getEvents = createAsyncThunk("member/find-events", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`member/find-events/${bodyData}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const likeTheNews = createAsyncThunk("member/like-news", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`member/like-news/${bodyData}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);


export const getAllPlans = createAsyncThunk("plan-list", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`plan-list/${bodyData}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const signOut = createAsyncThunk("/member/sign-out", async (bodyData = null, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/member/sign-out/`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const getInvoiceDetail = createAsyncThunk("invoice-detail", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`invoice-detail/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const chatList = createAsyncThunk("/member/chat-list", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`/member/chat-list`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const getUsersToStartChat = createAsyncThunk("/member/get-users-for-chat", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`/member/get-users-for-chat/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const updateVisitingStatus = createAsyncThunk("/member/update-visiting-status", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`/member/update-visiting-status/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const addVisitingRequest = createAsyncThunk("/member/create-visiting", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/member/create-visiting`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const getVisitingForMember = createAsyncThunk("/member/list-of-visiting-for-member", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`/member/list-of-visiting-for-member/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);
export const sendTheAttachment = createAsyncThunk("/member/send-attachment", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/member/send-attachment`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const deletePrintandScan = createAsyncThunk("/member/delete-print-and-scan/", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.delete(`/member/delete-print-and-scan/${bodyData}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);


export const addLikeAndUnlike = createAsyncThunk("/member/like-unlike-plan", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`/member/like-unlike-plan`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const getSpacesForDropDown = createAsyncThunk("/member/get-spaces-for-drop-down", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`/member/get-spaces-for-drop-down/${bodyData}`,);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const getAllFavortiePlans = createAsyncThunk("/member/list-of-liked-plans", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`/member/list-of-liked-plans`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getCustomersForDropDown = createAsyncThunk("/get-customers-for-booking-dropdown", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`/get-customers-for-booking-dropdown/${bodyData}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getRoomsAndDesksForDropDown = createAsyncThunk("/get-desks-rooms-for-booking-dropdown", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/get-desks-rooms-for-booking-dropdown/${bodyData}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const getPlansForBookingForDropDown = createAsyncThunk("/get-plans-for-booking", async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/get-plans-for-booking/${bodyData}`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const getCountry = createAsyncThunk("/all-country", async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.get(`/all-country`, bodyData);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
}
);

export const dashboard = createAsyncThunk('admin/dashboard', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/dashboard`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


export const getByBusinessData = createAsyncThunk('/get-my-business', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/get-my-business`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const userList = createAsyncThunk('/user-list', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/user-list/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


export const getAllSpaces = createAsyncThunk('/space-list', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/space-list/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getAllRooms = createAsyncThunk('/room-list', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/room-list/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getAllDesks = createAsyncThunk('/desk-list', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/desk-list/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const leadsList = createAsyncThunk('/lead-list', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/lead-list/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getAllActivity = createAsyncThunk('/get-activity', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/get-activity/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const createActivity = createAsyncThunk('/add-activity', async (bodyData, { rejectWithValue }) => {
    try {
        console.log()
        const response = await axios.post(`/add-activity`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const createReminder = createAsyncThunk('/add-reminder', async (bodyData, { rejectWithValue }) => {
    try {
        console.log()
        const response = await axios.post(`/add-reminder`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getAllReminder = createAsyncThunk('/get-reminder', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/get-reminder/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const reminderDelete = createAsyncThunk('/delete-reminder', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/delete-reminder/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const activityDelete = createAsyncThunk('/delete-activity', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/delete-activity/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const reminderEdit = createAsyncThunk('/edit-reminder', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/edit-reminder`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const activityEdit = createAsyncThunk('/edit-activity', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/edit-activity`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})



export const recentMemes = createAsyncThunk('admin/recentMemes', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/recentMemes`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getAllUsers = createAsyncThunk('admin/get-user-list', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/get-user-list`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getAllCategories = createAsyncThunk('admin/get-all-categries', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/get-all-categries`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const getAllSounds = createAsyncThunk('admin/get-sound-list', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/get-sound-list`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const addPackage = createAsyncThunk('admin/addPackage', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`admin/addPackage`, bodyData)

        return response.data

    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const addCategory = createAsyncThunk('admin/add-category', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`admin/add-category`, bodyData)

        return response.data

    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const createSound = createAsyncThunk('admin/create-sound-by-admin', async (bodyData, { rejectWithValue }) => {
    try {

        const response = await axios.post(`admin/create-sound-by-admin`, bodyData)

        return response.data

    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
export const updatePackage = createAsyncThunk('/admin/updatePackage/', async (bodyData, { rejectWithValue }) => {
    try {


        const response = await axios.post(`/admin/updatePackage/${bodyData?.id}`, bodyData)

        return response.data

    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const getPackages = createAsyncThunk('/api/getPackages', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/api/getPackages`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const deleteTemplate = createAsyncThunk('admin/deleteTemplate', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`admin/deleteTemplate/${bodyData}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


export const getReportedMeme = createAsyncThunk('admin/getReportedMeme', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/getReportedMeme`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const approveDisapproveReport = createAsyncThunk('admin/approveDisapproveReport', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`admin/approveDisapproveReport/${bodyData.id}`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const deleteAccount = createAsyncThunk('admin/delete-user/', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/delete-user/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const deleteSound = createAsyncThunk('admin/delete-sound/', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/delete-sound/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const deleteCategory = createAsyncThunk('admin/delete-category/', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/delete-category/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const blockUnblock = createAsyncThunk('admin/blockunblock', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/block-user/${id}`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const updatePassword = createAsyncThunk('admin/update-password', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`admin/update-password`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const updateTcpp = createAsyncThunk('admin/TcPp', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`admin/TcPp`, bodyData)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const updateScript = createAsyncThunk('/admin/createScript', async (bodyData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/admin/createScript`, bodyData)

        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


export const userLogout = createAsyncThunk('admin/sign-out', async (bodyData = null, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/sign-out`)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


export const TcPp = createAsyncThunk('admin/getTcPp', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`admin/getTcPp`)

        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


export const getScriptData = createAsyncThunk('/admin/getScript', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/admin/getScript`)

        return response.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {

        token: (state) => {
            var user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                state.user = user
            }
        }
    },
    extraReducers
})

export const getUserStatus = (state) => state?.users?.status;
export const getUserError = (state) => state?.users?.error;
export const getUserToken = (state) => state?.users?.user?.token;
export const getProfile = (state) => state?.users?.user;
export const getTcPp = (state) => state?.users?.TcPp;
export const getDashboard = (state) => state?.users?.dashboard;
export const getareaChart = (state) => state?.users?.areaChart;
export const getlineChart = (state) => state?.users?.lineChart;
export const getMemes = (state) => state?.users?.memes;
export const getBusiness = (state) => state?.users?.business;

export const { token } = userSlice.actions

export default userSlice.reducer