import moment from "moment";
import React, { useEffect, useState } from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useLocation, useNavigate } from "react-router-dom";
import EventMap from "./EventMap";
import { getEventDetail } from "../../store/slices/userSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { CalendarClock, MapPin } from "lucide-react";

const EventDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation()
  const dispatch = useDispatch()

  const [photoIndex, setphotoIndex] = useState(0)
  const [detail, setdetail] = useState()
  const [isOpen, setisOpen] = useState()


  const newsDetail = async () => {
    try {
      const response = await dispatch(getEventDetail(location?.state?._id)).unwrap();
      setdetail(response?.data)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  console.log(detail)

  useEffect(() => {
    let mount = true;
    if (mount) {
      newsDetail()
    }
    return () => {
      mount = false;
    };
  }, [location?.state])
  console.log(detail)
  return (
    <>
          <div className="sp-bookings-page pb-5 px-md-5 px-3">
      <nav aria-label="breadcrumb">
          <ol class="breadcrumb sp-breadcrumb">
              <li class="breadcrumb-item"><a>{t('menu')}</a></li>
              <li class="breadcrumb-item"><a onClick={() => navigate("/")}>{t('home')}</a></li>
              <li class="breadcrumb-item active" aria-current="page">{t('event')} {t('detail')}</li>
          </ol>
      </nav>
        <div className="row mb-3">
            <div className="col-md-6 d-flex align-items-center gap-2">
            <img height="32" src="../assets/images/home-icon-sp.svg" className="icon-text" />
            <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{detail?.name}</h2>
            </div>
          </div>
          <div className="sp-detail-image position-relative mb-3">
            <img alt="" src={`${process.env.REACT_APP_IMGURL}/${detail?.attachment[0]}`} className="w-100" />
            <div className="bottom-box d-flex align-items-center gap-3">
            <span className="font-size-14 py-2 px-3 text-capitalize white-color fw-medium l-blue-bg rounded-5">{detail?.type}</span>
              <button type="button" onClick={() => setisOpen(true)} class="btn sp-btn sp-white-btn py-2 px-3 rounded-5">{t('seeAllPhotos')} ({detail?.attachment.length})</button>
            </div>
          </div>
          {isOpen && (
              <Lightbox
                mainSrc={`${process.env.REACT_APP_IMGURL}/${detail?.attachment[photoIndex]}`}
                nextSrc={detail?.attachment[(photoIndex + 1) % detail?.attachment?.length]}
                prevSrc={detail?.attachment[(photoIndex + detail?.attachment?.length - 1) % detail?.attachment?.length]}
                onCloseRequest={() => setisOpen(false)}
                onMovePrevRequest={() =>
                  setphotoIndex((photoIndex + detail?.attachment?.length - 1) % detail?.attachment?.length)
                }
                onMoveNextRequest={() =>
                  setphotoIndex((photoIndex + 1) % detail?.attachment?.length)
                }
              />
            )}

            <div className="row mb-3">
                <div className="col-md-6 d-flex align-items-center gap-2">
                <MapPin stroke="var(--color5)" />
                <div>
                <p className="font-size-12 light-blue-color mb-0 fw-semibold">{t('location')}</p>
                <p className="font-size-14 black-color mb-0">{detail?.location?.address}</p>
                </div>
                </div>
                <div className="col-md-6 d-flex align-items-center gap-2">
                <CalendarClock stroke="var(--color5)" />
                <div>
                <p className="font-size-12 light-blue-color mb-0 fw-semibold">{t('dateAndTime')}</p>
                <p className="font-size-14 black-color mb-0">{moment(detail?.startTime).format("MMMM Do YYYY")} - {moment(detail?.endTime).format("MMMM Do YYYY")}</p>
                <p className="font-size-14 black-color mb-0">{moment(detail?.startTime).format("h:mm a")} - {moment(detail?.endTime).format("h:mm a")}</p>
                </div>
                </div>
            </div>
              <p className="font-size-18 light-blue-color titilium-font fw-semibold mb-0">{t('description')}</p>
              <p className="font-size-16 black-color titilium-font">{detail?.description}</p>


              <p className="font-size-18 light-blue-color titilium-font fw-semibold mb-0">{t('address')}</p>
              <EventMap lat={detail?.location?.coordinates[0]} long={detail?.location?.coordinates[1]} />
        </div>
    </>
  )
}

export default EventDetail