import { configureStore } from '@reduxjs/toolkit'
import usersReducer from './slices/userSlice';
import cartReducer from './slices/cartSlice';
import socketReducer from './slices/socketSlice';

const Store = configureStore({
  reducer: {
    users: usersReducer,
    carts: cartReducer,
    socket: socketReducer
  }
})
export default Store  