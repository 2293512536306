import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
    name: 'cart',
    initialState: { date: null, pricing: null, planData: null, cart: [] },
    reducers: {
        addItem: (state, action) => {
 
            state.date = action.payload.selectedRange
            state.planData = action.payload.planData
            state.pricing = action.payload.pricing
            state.cart = action.payload.cart
        },
        removeItem: (state, action) => {
            state.items = state.items.filter(item => item.id !== action.payload);
        },
        clearCart: state => {
            state.items = [];
        },
    },
});

export const { addItem, removeItem, clearCart } = cartSlice.actions;

export default cartSlice.reducer
