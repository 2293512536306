import React, { useContext, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { getProfile, getUserStatus, getUserToken } from "../../store/slices/userSlice"
import { context } from '../../context/context';
import Login from '../../pages/Auth/Login';
import ForgetPassword from '../../pages/Auth/ForgetPassword';
import ChangePassword from '../../pages/Auth/ChangePassword'
import Spinner from '../Spinner';
import BookingConfirmed from '../../pages/Core/BookingConfirmed';
import PlanDetails from '../../pages/Core/PlanDetails';
import Plans from '../../pages/Core/Plans';
import Bookings from '../../pages/Core/Bookings';
import BookingDetail from '../../pages/Core/BookingDetail';
import Header from './Header';
import SignUp from '../../pages/Auth/SignUp';
import VerifiedSuccess from '../../pages/Auth/VerifiedSuccess';
import VerifyAccount from '../../pages/Auth/VerifyAccount';
import SetUpPassword from '../../pages/Auth/SetupPassword';
import Chat from '../../pages/Core/Chat';
import FavoritePlans from '../../pages/Core/FavoritePlans';
import Tickets from '../../pages/Core/Tickets';
import AddTicket from '../../pages/Core/AddTicket';
import TicketDetail from '../../pages/Core/TicketDetail';
import Dashboard from '../../pages/Core/Dashboard';
import NewsDetail from '../../pages/Core/NewsDetail';
import EventDetail from '../../pages/Core/EventDetail';
import ProfileSetting from '../../pages/Core/ProfileSetting';
import MailAndPackages from '../../pages/Core/MailAndPackages';
import MailAndPackagesDetail from '../../pages/Core/MailAnPackagesDetail';
import PrintAndScan from '../../pages/Core/PrintAndScan';
import PrintScanDetail from '../../pages/Core/PrintScanDetail';
import AddPrintScan from '../../pages/Core/AddPrintScan';
import AddVisiting from '../../pages/Core/AddVisiting';
import Visitings from '../../pages/Core/Visitings';
import VisitingPage from '../../pages/Auth/VisitingPage';
import VisitingDetail from '../../pages/Core/VisitingDetail';
import SideBar from './SideBar';
import NavBar from './NavBar';
import { useTranslation } from 'react-i18next';
import Notification from './Notification';
import SelectBusiness from '../../pages/Auth/SelectBusiness';

const Layout = () => {


    const { isLoading } = useContext(context);
    const status = useSelector(getUserStatus)
    const user = useSelector(getProfile)
    const authToken = useSelector(getUserToken)

    const { i18n } = useTranslation();

    useEffect(() => {
      // Update lang and dir attributes based on the selected language
      document.documentElement.lang = i18n.language;
      document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
  
      // Add or remove the "rtl-arabic" class on the body tag
      if (i18n.language === 'ar') {
        document.body.classList.add('rtl-arabic');
      } else {
        document.body.classList.remove('rtl-arabic');
      }
    }, [i18n.language]);

    return (
        <>
            {/* {status === "loading" || isLoading ? <Spinner /> : <></>} */}
            <BrowserRouter>
                <Content authtoken={authToken} />
            </BrowserRouter>

        </>
    )
}

const Content = ({ authtoken }) => {
    var dash = "";
    const location = useLocation();
    const p = location.pathname.split('/');
    const p1 = p[p.length - 1];
    for (var i = p1.length - 1; i >= 0; i--) {
      if (p1[i] == '_') {
        console.log("trueeeeeeeee");
        dash = i;
        break;
      }
    }
    const t = decodeURIComponent(p1.replace(/-/g, ' '));
    function capitalizeEachWord(str) {
      return str.replace(/\b\w/g, (char) => char.toUpperCase());
    }
    
    useEffect(() => {
      document.title = location.pathname == "/" ? "Spaces Manager" : dash !== "" ? capitalizeEachWord(t.substring(0, dash)) : capitalizeEachWord(t);
    
    }, [dash, t]);

    return (
        <>
            {
                authtoken ? <>

                   <SideBar />
                    <NavBar />
                    <section className="content-section">
                    <Routes>
                        <Route path="*" element={<Navigate to="/" />} />
                        <Route path="/" exact element={<Dashboard />} />
                        <Route path="/notification" exact element={<Notification />} />
                        <Route path="/locations" exact element={<Plans />} />
                        <Route path="/plan-detail" exact element={<PlanDetails />} />
                        <Route path="/booking-confirmed" exact element={<BookingConfirmed />} />
                        <Route path="/booking-detail" exact element={<BookingDetail />} />
                        <Route path="/bookings" exact element={<Bookings />} />
                        <Route path="/messages" exact element={<Chat />} />
                        <Route path="/favorite-plans" exact element={<FavoritePlans />} />
                        <Route path="/tickets" exact element={<Tickets />} />
                        <Route path="/add-ticket" exact element={<AddTicket />} />
                        <Route path="/ticket-detail" exact element={<TicketDetail />} />
                        <Route path="/news-detail" exact element={<NewsDetail />} />
                        <Route path="/event-detail" exact element={<EventDetail />} />

                        <Route path="/profile-setting" exact element={<ProfileSetting />} />
                        <Route path="/mail-and-packages" exact element={<MailAndPackages />} />
                        <Route path="/mail-and-packages-detail" exact element={<MailAndPackagesDetail />} />

                        <Route path="/print-and-scan" exact element={<PrintAndScan />} />
                        <Route path="/print-and-scan-detail" exact element={<PrintScanDetail />} />
                        <Route path="/add-print-scan-request" exact element={<AddPrintScan />} />

                        <Route path="/visitings" exact element={<Visitings />} />
                        <Route path="/add-visiting" exact element={<AddVisiting />} />
                        <Route path="/visiting-detail" exact element={<VisitingDetail />} />
                    </Routes>
                    </section>
                </> :
                    <>

                        {location.pathname == "/login" || location.pathname == "/select-business" || location.pathname == "/forget-password" || location.pathname == "/change-password" || location.pathname == "/sign-up" || location.pathname.includes("/setup-password") || location.pathname.includes("/visitors") || location.pathname == "/verify-success" || location.pathname == "/verify-account" ? <></> : <Header />}

                        <Routes>
                            <Route path="*" element={<Navigate to="/login" />} />
                            <Route path="/login" exact element={<Login />} />
                            <Route path="/select-business" exact element={<SelectBusiness />} />
                            <Route path="/forget-password" exact element={<ForgetPassword />} />
                            <Route path="/change-password" exact element={<ChangePassword />} />
                            <Route path="/booking-confirmed" exact element={<BookingConfirmed />} />
                            <Route path="/setup-password/:userId/:resetLink" exact element={<SetUpPassword />} />
                            <Route path="/sign-up" exact element={<SignUp />} />
                            <Route path="/verify-success" exact element={<VerifiedSuccess />} />
                            <Route path="/verify-account" exact element={< VerifyAccount />} />
                            <Route path="/visitors/:id" exact element={< VisitingPage />} />
                        </Routes>
                    </>
            }
        </>
    )

};


export default Layout