import React, { useEffect, useState } from 'react'

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBookingList, getBusiness, getProfile } from '../../store/slices/userSlice';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const Bookings = () => {
    const { t } = useTranslation();
    const profile = useSelector(getProfile)
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [booking, setbooking] = useState()
    const [filterType, setfilterType] = useState("all")
    const [load, setload] = useState(false);
    const business = useSelector(getBusiness)
 
    const bookingList = async () => {
        try {
            setload(true)
            const response = await dispatch(getBookingList({ filterType })).unwrap();

            setbooking(response?.data)
            setload(false)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


    useEffect(() => {
        let mount = true;
        if (mount) {
            bookingList();
        }
        return () => {
            mount = false;
        };
    }, [filterType])

    return (
        <>
        <div className="sp-bookings-page pb-5 px-md-5 px-3">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb sp-breadcrumb">
                    <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{t('bookings')}</li>
                </ol>
            </nav>
            <div className="d-flex align-items-center gap-2 mb-4">
            <img height="32" src="../assets/images/bookings-icon-sp.svg" className="icon-text" />
            <h2 className="font-size-24 titilium-font fw-bold black-color mb-0">{t('bookings')}</h2>
            </div>

            <div className="sp-shadow sp-radius-20 white-bg p-3">
            <ul className="sp-booking-filter d-flex">
                <li className={filterType == "all" ? "active" : ""} onClick={() => setfilterType("all")}>{t('all')}</li>
                <li className={filterType == "upcoming" ? "active" : ""} onClick={() => setfilterType("upcoming")}>{t('upcoming')}</li>
                <li className={filterType == "current" ? "active" : ""} onClick={() => setfilterType("current")}> {t('current')}</li>
                <li className={filterType == "past" ? "active" : ""} onClick={() => setfilterType("past")}>{t('past')}</li>
            </ul>

            {load ? new Array(9).fill(null).map((_, index) => (
            <div className="border-bottom pb-3 border-top cursor-pointer" key={index}>
            <div className="d-flex justify-content-between  pt-3 mb-3">
                <div className="shimmer" style={{height:'20px', width:'180px'}}></div>
                <div className="shimmer" style={{height:'20px', width:'180px'}}></div>
                <div className="shimmer" style={{height:'20px', width:'180px'}}></div>
                <div className="shimmer" style={{height:'20px', width:'180px'}}></div>
            </div>
                <div className="d-flex gap-3" key={index}>
                    
                    <div>
                    
                    </div>
                </div>
                <div className="row  py-3">
                    <div className="col-md-6 d-flex gap-3">
                    <div className="shimmer sp-detail-image-list"></div>
                        <div>
                        <div className="shimmer mb-1" style={{height:'20px', width:'150px'}}></div>
                        <div className="shimmer mb-1" style={{height:'16px', width:'200px'}}></div>
                        <div className="shimmer mb-1" style={{height:'16px', width:'180px'}}></div>
                        <div className="shimmer mb-1" style={{height:'16px', width:'120px'}}></div>
                        </div>
                    </div>
                    <div className="col-md-6 align-self-center d-flex gap-5 justify-content-end">
                        <div className="d-flex flex-column gap-2 justify-content-end">
                        <div className="shimmer mb-1" style={{height:'16px', width:'120px'}}></div>
                        <div className="shimmer mb-1" style={{height:'16px', width:'80px'}}></div>
                        </div>
                        <div className="d-flex flex-column gap-2 justify-content-end">
                        <div className="shimmer mb-1" style={{height:'16px', width:'120px'}}></div>
                        <div className="shimmer mb-1" style={{height:'16px', width:'80px'}}></div>
                        </div>
                    </div>
                </div>
                </div>)) :booking?.map((b, i) => (
                <div className="border-bottom border-top cursor-pointer" key={i} onClick={() => navigate("/booking-detail", { state: b })}>
                    <div className="d-flex justify-content-between  pt-3">
                        <p className="font-size-20 titilium-font black-color fw-bold mb-0">{t('booking')} {t('number')}: <span className="light-blue-color">{b.bookingNumber}</span> </p>
                        <p className="font-size-20 titilium-font black-color fw-bold mb-0">{t('booking')} {t('date')}: <span className="light-blue-color">{moment(b.startDate).format("MMMM Do YYYY")}</span> </p>
                        <p className="font-size-20 titilium-font black-color fw-bold mb-0">{t('status')}: <span className="light-blue-color">{b.status}</span> </p>
                        <p className="font-size-20 titilium-font black-color fw-bold mb-0">{t('total')}: <span className="light-blue-color">{business?.currency} {b.depositAmount + b.totalAmount}</span> </p>
                        {/* <p className="font-size-14 titilium-font black-color mb-0">{t('deposit')} {business?.currency} {b?.depositAmount} + {t('price')} {business?.currency} {b?.subtotal} - {t('discount')} {b?.discount}</p> */}
                        
                    </div>
                    {b?.carts?.map((data, index) => (
                            <div className="row  py-3" key={index}>
                                <div className="col-md-6 d-flex gap-3">
                                    <img alt="" src={`${process.env.REACT_APP_IMGURL}/${data.roomId ? data?.roomId?.image[0] : data.virtualOfficeId ? data?.virtualOfficeId?.image[0] : data?.deskCategoryId?.image[0]}`} className="sp-detail-image-list" />
                                    <div>
                                        <p className="font-size-20 titilium-font black-color fw-bold mb-0">{data?.planData?.name}</p>
                                        {/* <p className="font-size-16 titilium-font black-color mb-0">Duration <span className="light-blue-color">3 hours</span></p> */}
                                        <p className="font-size-16 titilium-font black-color mb-0">{t('name')} <span className="light-blue-color">{data?.roomId ? data?.roomId?.name : data?.deskCategoryId?.name}</span></p>
                                        <p className="font-size-16 titilium-font black-color mb-0">{t('type')} <span className="light-blue-color"> {data?.roomId ? data?.roomId?.type : data?.deskCategoryId?.type}</span></p>
                                        <p className="font-size-16 titilium-font black-color mb-0">{t('quantity')} <span className="light-blue-color">{data?.count ? data?.count : 1}</span></p>
                                    </div>
                                </div>
                                <div className="col-md-6 align-self-center d-flex gap-5 justify-content-end">
                                    <div className="text-end">
                                        <p className="font-size-16 titilium-font black-color mb-0 fw-semibold">{t('deposit')}</p>
                                        <p className="font-size-16 titilium-font light-blue-color mb-0 fw-bold">{business?.currency} {data?.planData?.deposit}</p>
                                    </div>
                                    <div className="text-end">
                                        <p className="font-size-16 titilium-font black-color mb-0 fw-semibold">{t('price')}</p>
                                        <p className="font-size-16 titilium-font light-blue-color mb-0 fw-bold">{business?.currency} {data?.pricing?.price}</p>
                                    </div>
                                </div>
                            </div>

                        ))
                    }

                </div>
            ))}
        </div>
        </div>
        </>
    )
}

export default Bookings