import React, { useEffect, useState } from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { CalendarClock, Heart, MapPin, Scale3D, User } from 'lucide-react'
import { format } from 'date-fns';
import { DateRange, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { addLikeAndUnlike, availableSlots, checkDeskAvaibility, checkHotDeskAvaibility, getBusiness, getDeskCountSlice, getPlanDetail, getProfile, getUserToken, memberBooking } from "../../store/slices/userSlice";
import { addItem } from "../../store/slices/cartSlice"
import moment from "moment/moment";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/Spinner";


const PlanDetails = () => {
  const { t } = useTranslation();
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const authToken = useSelector(getUserToken)
  const profile = useSelector(getProfile)
  const cartItems = useSelector(state => state.carts);
  const [load, setload] = useState(false);
  const business = useSelector(getBusiness)

  const [selectedDate, setSelectedDate] = useState(null);
  const [toggle1, settoggle1] = useState(true);
  const [toggle2, settoggle2] = useState(false);
  const [toggle3, settoggle3] = useState(false);
  const [toggle4, settoggle4] = useState(false);
  const [toggle5, settoggle5] = useState(false);
  const [planData, setplanData] = useState(cartItems?.planData ? cartItems?.planData : null);
  const [pricing, setpricing] = useState(cartItems?.pricing ? cartItems?.pricing : null);
  const [plans, setplans] = useState(null);
  const [cartJson, setcartJson] = useState(cartItems?.cart ? cartItems?.cart : null);
  const [roomDeskId, setroomDeskId] = useState(null);
  const [depositTotal, setdepositTotal] = useState(0);
  const [discount, setdiscount] = useState(0);
  const [tax, settax] = useState(0);
  const [dueAmount, setdueAmount] = useState(0);
  const [totalAmount, settotalAmount] = useState(0);
  const [subtotal, setsubtotal] = useState(0);
  const [planDetails, setplanDetails] = useState()
  const [otherItems, setotherItems] = useState()
  const [getBookedData, setgetBookedData] = useState()
  const [paymentMethods, setpaymentMethods] = useState([]);
  const [allSlots, setallSlots] = useState([]);
  const [selectHours, setselectHours] = useState(null);
  const [userRequestedDeskCount, setuserRequestedDeskCount] = useState(null);
  const [startTime, setstartTime] = useState(null);
  const [startDeskTime, setstartDeskTime] = useState(null);
  const [endTime, setendTime] = useState(null);
  const [endDeskTime, setendDeskTime] = useState(null);
  const [enableDaysForSingleCalender, setenableDaysForSingleCalender] = useState(null);
  const [selectedRange, setSelectedRange] = useState(cartItems?.date ? cartItems?.date : { from: null, to: null });
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([]);
  const [photoIndex, setphotoIndex] = useState(0)
  const [isOpen, setisOpen] = useState()
  const [totalCount, settotalCount] = useState(0)
  const [threeMonth, setthreeMonth] = useState(new Date());
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [disableEndDate, setdisableEndDate] = useState("");
  const [isEndToggle, setisEndToggle] = useState(false);
  const [handleMonth, sethandleMonth] = useState(new Date());


  console.log("startDate", startDate)
  console.log("endDate", endDate)

  console.log("handleMonth", handleMonth)
  useEffect(() => {
    // Function to add three months to the current date
    const addThreeMonths = (date) => {
      const newDate = new Date(date);
      newDate.setMonth(newDate.getMonth() + 3);
      return newDate;
    };

    // Set the date after three months in the state
    setthreeMonth(addThreeMonths(new Date()));
  }, []);


  // For Default Month
  const handleToggle = () => {
    setisEndToggle(!isEndToggle);

    if (pricing?.type === "Yearly") {
      const nextYearSameMonth = new Date(handleMonth);
      nextYearSameMonth.setFullYear(nextYearSameMonth.getFullYear() + 1);
      sethandleMonth(nextYearSameMonth);
    } else if (pricing?.type === "Monthly") {
      const nextMonthSameDay = new Date(handleMonth);
      nextMonthSameDay.setMonth(nextMonthSameDay.getMonth() + 1);
      sethandleMonth(nextMonthSameDay);
    } else if (pricing?.type === "Weekly") {
      const nextWeekSameDay = new Date(handleMonth);
      nextWeekSameDay.setDate(nextWeekSameDay.getDate() + 7);
      sethandleMonth(nextWeekSameDay);
    }
  };

  useEffect(() => {
    sethandleMonth(startDate)
  }, [startDate])
  useEffect(() => {
    sethandleMonth(startDate)
  }, [pricing])
  // For Default Date
  useEffect(() => {
    const addCustomDate = (date, duration) => {
      const newDate = new Date(date);

      switch (duration?.type) {
        case 'Weekly':
          newDate.setDate(newDate.getDate() + 7);
          break;
        case 'Monthly':
          newDate.setMonth(newDate.getMonth() + 1);
          break;
        case 'Yearly':
          newDate.setMonth(newDate.getMonth() + 12);
          break;
        default:
          break;
      }

      return newDate;
    };

    setendDate(addCustomDate(startDate, pricing));
    setdisableEndDate(addCustomDate(startDate, pricing));


  }, [startDate]);

  // Effect for handling changes in pricing
  useEffect(() => {
    // You can handle other logic related to pricing here
    const addCustomDate = (date, duration) => {
      const newDate = new Date(date);

      switch (duration?.type) {
        case 'Weekly':
          newDate.setDate(newDate.getDate() + 7);
          break;
        case 'Monthly':
          newDate.setMonth(newDate.getMonth() + 1);
          break;
        case 'Yearly':
          newDate.setMonth(newDate.getMonth() + 12);
          break;
        default:
          break;
      }

      return newDate;
    };

    setendDate(addCustomDate(startDate, pricing));
    setdisableEndDate(addCustomDate(startDate, pricing));


  }, [pricing]);



  const getAllPlans = async () => {
    try {

      const response = await dispatch(getPlanDetail({ id: location?.state?._id, type: location?.state?.behave })).unwrap();

      setplanDetails(response?.data[0])
      setgetBookedData(response?.data[0]?.bookedDates?.map(booking => ({
        start: new Date(booking.start).setHours(0, 0, 0, 0),
        end: new Date(booking.end).setHours(0, 0, 0, 0)
      })));
      setenableDaysForSingleCalender(response?.data[0]?.spaceDetail?.days)
      setotherItems(response?.otherItems)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const getAvailableSlots = async () => {
    try {
      let payload = {
        slotDurationInHours: selectHours,
        id: planDetails._id,
        date: moment(selectedDate).format("YYYY-MM-DD")
      }
      const response = await dispatch(availableSlots(payload)).unwrap();
      setallSlots(response?.data)
      settoggle4(!toggle4)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };




  const getDeskCounts = async () => {
    try {
      const response = await dispatch(getDeskCountSlice(location?.state?._id)).unwrap();
      settotalCount(response?.count)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
      getAllPlans();
      getDeskCounts()
    }
    return () => {
      mount = false;
    };
  }, [])

  useEffect(() => {
    let mount = true;
    if (mount) {
      if (selectedDate && selectHours) {
        getAvailableSlots();
      }
    }
    return () => {
      mount = false;
    };
  }, [selectedDate, selectHours])

  const numberOfMonths = window.innerWidth > 768 ? 2 : 1;

  const handlePlan = (data) => {

    setpricing(data?.pricingType)
    setplanData(data?.planName)
    setcartJson([{ planData: data.planName, pricing: data.pricingType, [planDetails.behave === "Room" ? "roomId" : planDetails.behave === "Virtual Office" ? "virtualOfficeId" : "deskCategoryId"]: roomDeskId, ...(planDetails.behave === "Desk" ? { count: userRequestedDeskCount ? userRequestedDeskCount : 1 } : null) }])
    settoggle2(true);
    setisEndToggle(false)
    setendDate(null)
    setdisableEndDate("")
    sethandleMonth(null)
  }

  const updateCartJson = (deskCount) => {
    // Assuming planDetails and roomDeskId are accessible in this scope
    const updatedCartJson = [
      {
        planData: planData, // replace planData with the actual state or variable
        pricing: pricing, // replace pricing with the actual state or variable
        [planDetails.behave === "Room" ? "roomId" : "deskCategoryId"]: roomDeskId,
        count: deskCount,
      },
    ];

    setcartJson(updatedCartJson);
  };

  const handleRadioChange = (index) => {
    setuserRequestedDeskCount(index + 1);
    updateCartJson(index + 1);
    settoggle3(true);
  };

  const handleTimeSlots = (data) => {
    setstartTime(data?.startTime)
    setendTime(data?.endTime)
    settoggle5(true);
  }



  const isDateBooked = (date) => {
    return getBookedData?.some((booking) => {
      return date >= booking.start && date <= booking.end;
    });
  };


  const handleDayClick = (day) => {
    setisEndToggle(false)
    setendDate(null)
    setdisableEndDate("")
    sethandleMonth(null)

    const selectedDateTimestamp = Date.parse(day);

    if (!selectedRange.from || selectedRange.to) {
      // If no start date is selected or both start and end dates are selected, start a new range
      setSelectedRange({ from: selectedDateTimestamp, to: null });
    } else if (selectedDateTimestamp > selectedRange.from) {
      // If a start date is selected and the clicked date is after it, set it as the end date
      setSelectedRange((prevRange) => ({ ...prevRange, to: selectedDateTimestamp }));

      // Check if any date within the range is booked
      const isRangeBooked = getBookedData.some(
        (booking) => {
          for (let date = selectedRange.from; date <= selectedDateTimestamp; date += 86400000) {
            // 86400000 milliseconds = 1 day
            if (date >= booking.start && date <= booking.end) {
              return true; // If any date within the range is booked, return true
            }
          }
          return false;
        }
      );

      if (isRangeBooked) {
        // If any date within the range is booked, reset the range
        setSelectedRange({ from: selectedDateTimestamp, to: null });
      }
    } else {
      // If a start date is selected and the clicked date is before it, start a new range
      setSelectedRange({ from: selectedDateTimestamp, to: null });
    }
    settoggle5(true)
  };

  const handleDayClickForEndDate = (day) => {

    const selectedDateTimestamp = Date.parse(day);

    if (!selectedRange.from || selectedRange.to) {
      // If no start date is selected or both start and end dates are selected, start a new range
      setSelectedRange({ from: selectedDateTimestamp, to: null });
    } else if (selectedDateTimestamp > selectedRange.from) {
      // If a start date is selected and the clicked date is after it, set it as the end date
      setSelectedRange((prevRange) => ({ ...prevRange, to: selectedDateTimestamp }));

      // Check if any date within the range is booked
      const isRangeBooked = getBookedData.some(
        (booking) => {
          for (let date = selectedRange.from; date <= selectedDateTimestamp; date += 86400000) {
            // 86400000 milliseconds = 1 day
            if (date >= booking.start && date <= booking.end) {
              return true; // If any date within the range is booked, return true
            }
          }
          return false;
        }
      );

      if (isRangeBooked) {
        // If any date within the range is booked, reset the range
        setSelectedRange({ from: selectedDateTimestamp, to: null });
      }
    } else {
      // If a start date is selected and the clicked date is before it, start a new range
      setSelectedRange({ from: selectedDateTimestamp, to: null });
    }
  };

  // const disabledDays = (day) => {
  //   if (!getBookedData) {
  //     return false;
  //   }

  //   return isDateBooked(day);
  // };

  const disabledDays = (day) => {
    // if (!getBookedData) {
    //     return false;
    // }
    // return isDateBooked(day);

    const today = new Date(); // Get the current date
    today.setHours(0, 0, 0, 0);
    return !getBookedData || day < today || isDateBooked(day);


  };


  const disabledDaysForDesk = (day) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set hours to 0 to compare only dates

    // Disable dates prior to today
    if (day < today) {
      return true;
    }

    if (!getBookedData) {
      return false;
    }
    return isDateBooked(day);
  };


  useEffect(() => {
    const combinedPlans = [];
    planDetails?.planDetail?.forEach((plan) => {
      plan.pricing.forEach((pricingOption) => {
        const combinedPlan = {
          planName: plan,
          pricingType: pricingOption,
        };
        combinedPlans.push(combinedPlan);
      });
    });

    setroomDeskId(planDetails?._id)
    setplans(combinedPlans)

  }, [planDetails])


  const calculateSubtotal = () => {
    let totalSubtotal = 0;
    let depositTotal = 0;

    for (const item of cartJson) {
      if (item.planData && item.pricing) {
        // For the first object
        if (item?.count) {

          const { count } = item;
          const { price } = item.pricing;
          const { deposit } = item.planData;
          const subtotal1 = count * price;
          const depositTotal1 = deposit

          totalSubtotal += subtotal1;
          depositTotal += depositTotal1
        }
        if (!item.count) {
          if (planDetails?.type == "Private Room" || planDetails?.type == "Executive Cabin") {
            console.log("xxxxxx", planDetails)
            const { deposit } = item.planData;
            const { price } = item.pricing;
            totalSubtotal += price;
            depositTotal += deposit;
          } else {
            console.log("yyyyyyy", planDetails)
            const { deposit } = item?.planData;
            const { price } = item?.pricing;
            const subtotal1 = (selectHours ? selectHours : 1) * price;
            totalSubtotal += subtotal1;
            depositTotal += deposit;
          }
        }
      }
    }

    console.log(planDetails)

    setdepositTotal(Math.round(depositTotal))
    setsubtotal(Math.round(totalSubtotal))
    settotalAmount(Math.round(totalSubtotal))
    return depositTotal;
  };



  useEffect(() => {
    calculateSubtotal()
  }, [cartJson])

  useEffect(() => {
    calculateSubtotal()
  }, [selectHours])


  function checkOverlap(selectedRange, bookedSlots) {
    const selectedStart = new Date(selectedRange.from).getTime();
    const selectedEnd = new Date(selectedRange.to).getTime();
    for (const slot of bookedSlots) {
      const slotStart = slot.start;
      const slotEnd = slot.end;
      if (selectedStart < slotEnd && selectedEnd > slotStart) {
        // There is an overlap
        return true;
      }
    }
    // No overlap
    return false;
  }



  const addToCart = async (e) => {
    e.preventDefault()
    try {
      setload(true);
      if (planDetails?.type === "Private Room" || planDetails?.type === "Executive Cabin") {

        let payload = {
          userId: profile?._id,
          cart: cartJson,
          spaceId: planDetails?.spaceDetail._id,
          paymentType: pricing?.type,
          // startDate: selectedRange.from ? moment(selectedRange.from).format("YYYY-MM-DD") : null,
          // endDate: selectedRange?.to ? moment(selectedRange?.to).format("YYYY-MM-DD") : null,
          startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
          depositAmount: depositTotal,
          totalAmount: totalAmount,
          discount,
          tax,
          paymentMethods: selectedPaymentMethods,
          subtotal,
          businessId: planDetails?.businessId,
          paymentType: pricing?.type,
          dueAmount: depositTotal + totalAmount
        }

        const response = await dispatch(memberBooking(payload)).unwrap();
        navigate("/booking-detail", { state: response?.data })
        setload(false);

      } else if (planDetails?.behave == "Desk") {
        let payload = {
          userId: profile?._id,
          cart: cartJson,
          spaceId: planDetails?.spaceDetail?._id,
          paymentType: pricing?.type,
          // startDate: selectedRange.from ? moment(selectedRange.from).format("YYYY-MM-DD") : null,
          // endDate: selectedRange?.to ? moment(selectedRange?.to).format("YYYY-MM-DD") : null,
          startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
          depositAmount: depositTotal,
          totalAmount: totalAmount,
          startTime: startDeskTime ? startDeskTime : null,
          endTime: endDeskTime ? endDeskTime : null,
          discount,
          paymentMethods: selectedPaymentMethods,
          tax,
          subtotal: subtotal,
          businessId: planDetails?.businessId,
          paymentType: pricing?.type,
          dueAmount: depositTotal + (totalAmount),
        }


        const response = await dispatch(memberBooking(payload)).unwrap();
        navigate("/booking-detail", { state: response?.data })
        setload(false);
      } else if (planDetails?.behave == "Virtual Office") {


        let payload = {
          userId: profile?._id,
          cart: cartJson,
          spaceId: planDetails?.spaceDetail?._id,
          paymentType: pricing?.type,
          // startDate: selectedRange.from ? moment(selectedRange.from).format("YYYY-MM-DD") : null,
          // endDate: selectedRange?.to ? moment(selectedRange?.to).format("YYYY-MM-DD") : null,
          startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
          depositAmount: depositTotal,
          totalAmount: totalAmount,
          discount,
          paymentMethods: selectedPaymentMethods,
          tax,
          subtotal: subtotal,
          businessId: planDetails?.businessId,
          paymentType: pricing?.type,
          dueAmount: depositTotal + (totalAmount),
        }


        const response = await dispatch(memberBooking(payload)).unwrap();
        navigate("/booking-detail", { state: response?.data })
        setload(false);
      } else {
        let payload = {
          userId: profile?._id,
          cart: cartJson,
          spaceId: planDetails?.spaceDetail?._id,
          paymentType: pricing?.type,
          startDate: selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : null,
          endDate: moment(selectedDate).format("YYYY-MM-DD"),
          startTime: moment(startTime, 'hh:mm A').format('HH:mm'),
          endTime: moment(endTime, 'hh:mm A').format('HH:mm'),
          depositAmount: depositTotal,
          totalAmount: totalAmount,
          discount,
          paymentMethods: selectedPaymentMethods,
          tax,
          subtotal: subtotal,
          bookingHours: selectHours,
          businessId: planDetails?.businessId,
          paymentType: pricing?.type,
          dueAmount: depositTotal + (totalAmount),
        }

        const response = await dispatch(memberBooking(payload)).unwrap();
        navigate("/booking-detail", { state: response?.data })
        setload(false);
      }
    } catch (error) {
      setload(false);
      console.log(error)
    }

  };


  const likeUnLikePlan = async (data) => {
    try {

      let payload = {
        planId: data._id,
        behave: data.behave
      }
      await dispatch(addLikeAndUnlike(payload)).unwrap();
      getAllPlans()
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };



  const addData = () => {
    dispatch(addItem({ selectedRange, pricing, planData, cart: cartJson }));
    navigate("/login", { state: { _id: location?.state?._id, behave: location?.state?.behave } })
  };




  const isDisabled = (day) => {
    if (!enableDaysForSingleCalender) {
      return true; // Disable all days if enableDaysForSingleCalender is not set
    }

    // Disable past dates
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (day < today) {
      return true;
    }

    // Disable specific days
    const dayName = day.toLocaleDateString('en-US', { weekday: 'short' });

    console.log("dayName", dayName)
    return !enableDaysForSingleCalender.includes(dayName);
  };

  const getDeskBookedSlots = async () => {
    try {
      let paylaod = {
        userRequestedDeskCount,
        id: planDetails._id,
        startTime: startDeskTime,
        endTime: endDeskTime
      }

      if (planDetails?.type == "Hot Desk") {
        // const response = await dispatch(checkHotDeskAvaibility(paylaod)).unwrap();
        // setgetBookedData(response?.data?.map(booking => ({
        //   start: new Date(booking.start).setHours(0, 0, 0, 0),
        //   end: new Date(booking.end).setHours(0, 0, 0, 0)
        // })));

        const response = await dispatch(checkDeskAvaibility(paylaod)).unwrap();
        setgetBookedData(response?.data?.map(booking => ({
          start: new Date(booking.start).setHours(0, 0, 0, 0),
          end: new Date(booking.end).setHours(0, 0, 0, 0)
        })));


      } else {
        const response = await dispatch(checkDeskAvaibility(paylaod)).unwrap();
        setgetBookedData(response?.data?.map(booking => ({
          start: new Date(booking.start).setHours(0, 0, 0, 0),
          end: new Date(booking.end).setHours(0, 0, 0, 0)
        })));

      }

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  console.log(planDetails)

  useEffect(() => {
    if (userRequestedDeskCount) {
      getDeskBookedSlots()
    }
  }, [userRequestedDeskCount])


  const handleChange = (event) => {
    const selectedMethod = event.target.id;

    // Check if the selected method is already in the array
    if (selectedPaymentMethods.includes(selectedMethod)) {
      // If it is, remove it
      setSelectedPaymentMethods(prevMethods =>
        prevMethods.filter(method => method !== selectedMethod)
      );
    } else {
      // If it's not, add it to the array
      setSelectedPaymentMethods(prevMethods => [...prevMethods, selectedMethod]);
    }
  };



  const likeUnLikePlan2 = async (data) => {
    try {

        let payload = {
            planId: data._id,
            behave: data.behave

        }
        await dispatch(addLikeAndUnlike(payload)).unwrap();
        getAllPlans()
    } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
    }
};


  return (
    <>
      {load ? <Spinner /> : <></>}
      <div className="sp-bookings-page pb-5 px-md-5 px-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb sp-breadcrumb">
            <li class="breadcrumb-item"><a>{t('menu')}</a></li>
            <li class="breadcrumb-item"><a onClick={() => navigate("/locations")}>{t('location')}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{t('location')} {t('detail')}</li>
          </ol>
        </nav>

        <div className="row mb-3">
          <div className="col-md-6 d-flex align-items-center gap-2">
            <img height="32" src="./assets/images/locations-icon-sp.svg" className="icon-text" />
            <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{planDetails?.name}</h2>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <button onClick={() => likeUnLikePlan2(planDetails)} type="button" class="btn sp-btn sp-white-btn rounded-5 d-flex align-items-center gap-2 sp-shadow px-3 py-2">
            {planDetails?.isLiked ? <Heart stroke="var(--color5)" fill="var(--color5)" strokeWidth={1} /> : <Heart stroke="#3A5CD6" strokeWidth={1} /> }
              <span>{t('save')}</span>
            </button>
          </div>
        </div>
        <div className="sp-detail-image position-relative mb-3">
          <img alt="" src={`${process.env.REACT_APP_IMGURL}/${planDetails?.image[0]}`} className="w-100" />
          <div className="bottom-box d-flex align-items-center gap-3">
            <span className="font-size-14 py-2 px-3 text-capitalize white-color fw-medium l-blue-bg rounded-5">{planDetails?.type}</span>
            <button type="button" onClick={() => setisOpen(true)} class="btn sp-btn sp-white-btn py-2 px-3 rounded-5">{t('seeAllPhotos')} ({planDetails?.image.length})</button>
          </div>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={`${process.env.REACT_APP_IMGURL}/${planDetails?.image[photoIndex]}`}
            nextSrc={planDetails?.image[(photoIndex + 1) % planDetails?.image?.length]}
            prevSrc={planDetails?.image[(photoIndex + planDetails?.image?.length - 1) % planDetails?.image?.length]}
            onCloseRequest={() => setisOpen(false)}
            onMovePrevRequest={() =>
              setphotoIndex((photoIndex + planDetails?.image?.length - 1) % planDetails?.image?.length)
            }
            onMoveNextRequest={() =>
              setphotoIndex((photoIndex + 1) % planDetails?.image?.length)
            }
          />
        )}
        <div className="row gx-5 gy-5 mb-4">
          <div className="col-md-6">
            <div className="d-flex align-items-center gap-2 mb-3">
              <MapPin stroke="var(--color5)" />
              <div>
                <p className="font-size-12 light-blue-color mb-0 fw-semibold">{t('location')}</p>
                <p className="font-size-16 black-color mb-0">{planDetails?.spaceDetail?.location?.address}, {planDetails?.spaceDetail?.location?.city}, {planDetails?.spaceDetail?.location?.country}</p>
                {/* <p className="font-size-12 light-blue-color mb-0 cursor-pointer"><u>{t('viewCoworkingSpace')}</u></p> */}
              </div>
            </div>
            <div className="row mb-3 align-items-start">
              <div className="col-md-3 d-flex align-items-center gap-2">
                <CalendarClock stroke="var(--color5)" />
                <div>
                  <p className="font-size-12 light-blue-color mb-0 fw-semibold">{t('timing')}</p>
                  <p className="font-size-14 black-color mb-0">{`${planDetails?.spaceDetail?.spaceStartTime} - ${planDetails?.spaceDetail?.spaceEndTime}`}</p>
                </div>
              </div>
              <div className="col-md-3 d-flex align-items-center gap-2">
                <CalendarClock stroke="var(--color5)" />
                <div>
                  <p className="font-size-12 light-blue-color mb-0 fw-semibold">{t('days')}</p>
                  <p className="font-size-14 black-color mb-0">({planDetails?.spaceDetail?.days?.length > 0 && `${planDetails?.spaceDetail?.days[0]} - ${planDetails?.spaceDetail?.days[planDetails?.spaceDetail?.days.length - 1]}`})</p>
                </div>
              </div>
              <div className="col-md-3 d-flex align-items-center gap-2">
                <Scale3D stroke="var(--color5)" />
                <div>
                  <p className="font-size-12 light-blue-color mb-0 fw-semibold">{t('roomSize')}</p>
                  <p className="font-size-14 black-color mb-0">{planDetails?.deskSize ? planDetails?.deskSize : planDetails?.roomSize} Sq / ft</p>
                </div>
              </div>
              <div className="col-md-3 d-flex align-items-center gap-2">
                <User stroke="var(--color5)" />
                <div>
                  <p className="font-size-12 light-blue-color mb-0 fw-semibold">{t('occupancy')}</p>
                  <p className="font-size-14 black-color mb-0">{planDetails?.deskCount ? planDetails?.deskCount : planDetails?.capacity}</p>
                </div>
              </div>
            </div>
            <p className="font-size-18 light-blue-color titilium-font fw-semibold mb-0">{planDetails?.name} {t('detail')}</p>
            <p className="font-size-16 black-color titilium-font">{planDetails?.description}</p>

            {planData &&
              <> <p className="font-size-18 light-blue-color titilium-font fw-semibold mb-0">{planData?.name} ({pricing?.type}) {t('detail')}</p>
                <p className="font-size-16 black-color titilium-font">{planData?.description}</p></>}



          </div>

          <div className="col-md-6">

            <div className={`sp-toggle ${toggle1 && "opened"}`}>
              <div onClick={() => settoggle1(!toggle1)} className="d-flex align-items-center justify-content-between py-3 px-1">
                <p className="font-size-20 black-color titilium-font fw-bold mb-0">{t('select')} {t('plan')} <span className="light-blue-color">{planData?.name} {pricing?.type} {planData ? business?.currency : ""} {pricing?.price}</span> </p>
                <i class={`bi ${toggle1 ? "bi-chevron-down" : "bi-chevron-up"} light-blue-color`}></i>
              </div>
              <div className={`sp-toggle-item ${toggle1 && "show"}`}>
                <div className="sp-select-checked d-flex gap-2 flex-wrap">
                  {plans?.filter(plan => plan.pricingType.price !== 0)?.map((data, index) => (
                    <>
                      <input key={index} type="radio" name="plan" class="btn-check" onChange={() => handlePlan(data)} id={`${data.planName.name}-${data.pricingType.type}-checked`} />
                      <label class="btn btn-light" for={`${data?.planName?.name}-${data?.pricingType?.type}-checked`}>{data?.planName?.name} {data.pricingType.type} - {business?.currency} {data.pricingType.price}</label>
                    </>))}
                </div>
              </div>
            </div>

            {/* {planDetails?.type == "Hot Desk" && <div className={`sp-toggle ${toggle1 && "opened"}`}>
              <div onClick={() => settoggle1(!toggle1)} className="d-flex align-items-center justify-content-between py-3 px-1">
                <p className="font-size-20 black-color titilium-font fw-bold mb-0">{t('select')} {t('time')} <span className="light-blue-color">{startDeskTime} : {endDeskTime}</span> </p>
                <i class={`bi ${toggle1 ? "bi-chevron-down" : "bi-chevron-up"} light-blue-color`}></i>
              </div>
              <div className={`sp-toggle-item ${toggle1 && "show"}`}>
                <div class="d-flex gap-3">
                  <div class="form-floating sp-input w-100">
                    <input type="time" class="form-control" id="start-time" onChange={(e) => setstartDeskTime(e.target.value)} placeholder="Space Name" />
                    <label for="start-time">{t('startTime')}</label>
                  </div>
                  <div class="form-floating sp-input w-100">
                    <input type="time" class="form-control" id="end-time" onChange={(e) => setendDeskTime(e.target.value)} placeholder="Space Name" />
                    <label for="end-time">{t('endTime')}</label>
                  </div>
                </div>
              </div>
            </div>} */}

            {
              (planDetails?.type !== "Private Room" && planDetails?.type !== "Virtual Office" && planDetails?.type !== "Executive Cabin") && <div className={`sp-toggle ${toggle2 && "opened"}`}>
                <div onClick={() => settoggle2(!toggle2)} className="d-flex align-items-center justify-content-between py-3 px-1">
                  <p className="font-size-20 black-color titilium-font fw-bold mb-0">{planDetails?.behave == "Desk" ? <>{t('deskSelected')}</> : <>{t('selectHours')}</>} <span className="light-blue-color">{selectHours}</span> </p>
                  <i class={`bi ${toggle2 ? "bi-chevron-down" : "bi-chevron-up"} light-blue-color`}></i>
                </div>
                <div className={`sp-toggle-item ${toggle2 && "show"}`}>
                  <div className="sp-select-checked d-flex gap-2 flex-wrap">
                    {
                      planDetails?.behave == "Desk" ?
                        [...Array(totalCount)].map((_, index) => (
                          <>
                            <input
                              type="radio"
                              name="hours"
                              className="btn-check"
                              onChange={() => handleRadioChange(index)}
                              id={`hour-${index + 1}-checked`}
                            />
                            <label className="btn btn-light" htmlFor={`hour-${index + 1}-checked`}>
                              {index + 1} {t('desk')}
                            </label>
                          </>
                        )) :
                        [...Array(planDetails?.noOfHours)].map((_, index) => (
                          <>
                            <input
                              type="radio"
                              name="hours"
                              className="btn-check"
                              onChange={() => { setselectHours(index + 1); settoggle3(true) }}
                              id={`hour-${index + 1}-checked`}
                            />
                            <label className="btn btn-light" htmlFor={`hour-${index + 1}-checked`}>
                              {index + 1} {t('hour')}
                            </label>
                          </>
                        ))
                    }
                  </div>
                </div>


              </div>
            }
            <div className={`sp-toggle ${toggle3 && "opened"}`}>
              <div onClick={() => settoggle3(!toggle3)} className="d-flex align-items-center justify-content-between py-3 px-1">
                <p className="font-size-20 black-color titilium-font fw-bold mb-0">{t('Date')} <span className="light-blue-color"></span></p>

                <i class={`bi ${toggle3 ? "bi-chevron-down" : "bi-chevron-up"} light-blue-color`}></i>
              </div>
              <div className={`sp-toggle-item ${toggle3 && "show"}`}>
                <div className="sp-day-picker">
                  {planDetails?.type == "Private Room" || planDetails?.type == "Executive Cabin" ? <>
                    <div className="d-flex justify-content-between">
                      <DayPicker
                        mode="single"
                        // selected={selectedRange}
                        selected={startDate}
                        onSelect={setstartDate}
                        onDayClick={handleDayClick}
                        disabled={disabledDays}
                        toDate={threeMonth}
                      />
                      {isEndToggle ? <DayPicker
                        mode="single"
                        // selected={selectedRange}
                        selected={endDate}
                        onSelect={setendDate}
                        onDayClick={handleDayClickForEndDate}
                        defaultMonth={handleMonth}
                        // disabled={disabledDays}
                        disabled={{ before: disableEndDate }}
                      /> : <></>}
                    </div>
                    <div class="form-check sp-signup-checkbox mb-4">
                      <input class="form-check-input" type="checkbox" name="endRadio" id="endRadio" checked={isEndToggle} onChange={() => handleToggle()} />
                      <label class="form-check-label" for="endRadio">Do you want to provide end Date</label>
                    </div>
                  </> : planDetails?.behave == "Virtual Office" ? <>
                    <div className="d-flex justify-content-between">
                      <DayPicker
                        mode="single"
                        // selected={selectedRange}
                        selected={startDate}
                        onSelect={setstartDate}
                        onDayClick={handleDayClick}
                        toDate={threeMonth}
                      />
                      {isEndToggle ? <DayPicker
                        mode="single"
                        // selected={selectedRange}
                        selected={endDate}
                        onSelect={setendDate}
                        onDayClick={handleDayClickForEndDate}
                        defaultMonth={handleMonth}
                        disabled={{ before: disableEndDate }}
                      /> : <></>}
                    </div>
                    <div class="form-check sp-signup-checkbox mb-4">
                      <input class="form-check-input" type="checkbox" name="endRadio" id="endRadio" checked={isEndToggle} onChange={() => handleToggle()} />
                      <label class="form-check-label" for="endRadio">Do you want to provide end Date</label>
                    </div>
                  </> : planDetails?.behave == "Desk" ? <>
                    <div className="d-flex justify-content-between">
                      <DayPicker
                        mode="single"
                        // selected={selectedRange}
                        selected={startDate}
                        onSelect={setstartDate}
                        onDayClick={handleDayClick}
                        disabled={disabledDaysForDesk}
                        toDate={threeMonth}
                      />
                      {isEndToggle ? <DayPicker
                        mode="single"
                        // selected={selectedRange}
                        selected={endDate}
                        onSelect={setendDate}
                        onDayClick={handleDayClickForEndDate}
                        defaultMonth={handleMonth}
                        // disabled={disabledDays}
                        disabled={{ before: disableEndDate }}
                      /> : <></>}
                    </div>
                    <div class="form-check sp-signup-checkbox mb-4">
                      <input class="form-check-input" type="checkbox" name="endRadio" id="endRadio" checked={isEndToggle} onChange={() => handleToggle()} />
                      <label class="form-check-label" for="endRadio">Do you want to provide end Date</label>
                    </div>
                  </> :
                    <>
                      <DayPicker
                        mode="single"
                        selected={selectedDate}
                        // numberOfMonths={numberOfMonths}
                        disabled={(day) => isDisabled(day)}
                        onSelect={setSelectedDate}
                      />
                    </>
                  }

                </div>
              </div>
            </div>

            {(planDetails?.type === "Meeting Room" || planDetails?.type === "Podcast Room" || planDetails?.type === "Huddle Room" || planDetails?.type === "Event Space") && (
              <div className={`sp-toggle ${toggle4 && "opened"}`}>
                <div onClick={() => settoggle4(!toggle4)} className="d-flex align-items-center justify-content-between py-3 px-1">
                  <p className="font-size-20 black-color titilium-font fw-bold mb-0">{t('timeSlot')} <span className="light-blue-color">{startTime} - {endTime}</span></p>
                  <i className={`bi ${toggle4 ? "bi-chevron-down" : "bi-chevron-up"} light-blue-color`}></i>
                </div>

                <div className={`sp-toggle-item ${toggle4 && "show"}`}>
                  <div className="sp-select-checked d-flex gap-2 flex-wrap">
                    {allSlots?.map((time, index) => (
                      <div key={index}>
                        <input type="radio" name="time-slot" className="btn-check" id={`${time?.startTime}-time-slot`} onChange={() => handleTimeSlots(time)} />
                        <label className="btn btn-light" htmlFor={`${time.startTime}-time-slot`}>{time?.startTime} - {time?.endTime}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}


            <div className={`sp-toggle ${toggle5 && "opened"}`}>
              <div onClick={() => settoggle5(!toggle5)} className="d-flex align-items-center justify-content-between py-3 px-1">
                <p className="font-size-20 black-color titilium-font fw-bold mb-0">{t('paymentMethod')} <span className="light-blue-color text-capitalize">{selectedPaymentMethods.join(', ')}</span></p>

                <i class={`bi ${toggle5 ? "bi-chevron-down" : "bi-chevron-up"} light-blue-color`}></i>
              </div>

              <div className={`sp-toggle-item ${toggle5 && "show"}`}>
                <div className='d-flex sp-payment-select gap-2'>
                  <input onChange={handleChange} type="checkbox" checked={selectedPaymentMethods.includes('cash')} name="payment-method" class="btn-check" id="cash" />
                  <label class="btn d-flex gap-2 align-items-center w-50" for="cash">
                    <i class="bi bi-cash font-size-26 d-flex"></i>
                    <span className="font-size-20 titilium-font">{t('cash')}</span>
                  </label>

                  <input onChange={handleChange} checked={selectedPaymentMethods.includes('bank')} type="checkbox" name="payment-method" class="btn-check" id="bank" />
                  <label class="btn d-flex gap-2 align-items-center w-50" for="bank">
                    <i class="bi bi-bank font-size-26 d-flex"></i>
                    <span className="font-size-20 titilium-font">{t('bankTransfer')}</span>
                  </label>
                </div>
              </div>

            </div>

            <div className="sp-shadow p-3 mt-3">

              {
                planDetails?.behave == "Desk" ?
                  <div className="d-flex gap-2 align-items-end mb-2">
                    <h3 className="font-size-30 titilium-font light-blue-color fw-bold mb-0">{business?.currency} {pricing?.price ? totalAmount : ""}</h3>
                    < p className="font-size-14 black-color mb-1">for {userRequestedDeskCount} {t('desk')}</p>
                  </div>
                  :
                  planDetails?.type == "Private Room" || planDetails?.type == "Executive Cabin" ?
                    <div className="d-flex gap-2 align-items-end mb-2">
                      <h3 className="font-size-30 titilium-font light-blue-color fw-bold mb-0">{business?.currency} {totalAmount}</h3>
                      < p className="font-size-14 black-color mb-1">{t('for')}  {pricing?.type}</p>
                    </div>
                    :
                    < div className="d-flex gap-2 align-items-end mb-2">
                      <h3 className="font-size-30 titilium-font light-blue-color fw-bold mb-0">{business?.currency} {totalAmount}</h3>
                      < p className="font-size-14 black-color mb-1">{t('for')} {selectHours} {t('hour')}</p>
                    </div>
              }
              <div className="border p-1 mb-3">
                <p className="font-size-14 black-color mb-0 text-uppercase">{t('deposit')}</p>
                <p className="font-size-18 black-color mb-0">{business?.currency} {planData?.deposit} ({t('refundable')})</p>
              </div>

              {authToken ? <button type="button" class="btn sp-btn sp-l-blue-btn py-3 px-3 w-100" onClick={addToCart}  >{t('bookNow')}</button> : <button type="button" class="btn sp-btn sp-light-blue-btn py-3 px-3 w-100" onClick={addData}  >Login to book</button>}
            </div>

          </div>
        </div>
        {otherItems?.length > 0 && (
          <>
            <p className="font-size-24 titilium-font fw-bold blue-color mb-3">{t('other')} {t('packages')}</p>
            <div className="row gy-4">
              {otherItems?.map((data, index) => (
                <div className="col-md-2 sp-cards" key={index} >
                  <div className="sp-card-body">
                    <a onClick={() => navigate("/plan-detail", { state: data })}>
                      <img alt="" src={`${process.env.REACT_APP_IMGURL}/${data?.image[0]}`} className="sp-card-image w-100" />
                    </a>
                    <div className="d-flex justify-content-between align-items-center top-box">
                      <span className="font-size-14 py-1 px-3 text-capitalize white-color fw-medium" style={{ backgroundColor: 'var(--color5)' }}>{data?.type}</span>
                      {data?.isLiked ? <Heart fill="#3A5CD6" stroke="#FFFFFF" className="cursor-pointer" onClick={() => likeUnLikePlan(data)} /> : <Heart fill="#3131314D" stroke="#ffffff" className="cursor-pointer" onClick={() => likeUnLikePlan(data)} />}
                    </div>
                  </div>
                  <div className="sp-card-footer cursor-pointer">
                    <p className="font-size-16 black-color mb-0 titilium-font">{data?.spaceDetail?.name}</p>
                    <p className="font-size-18 light-blue-color mb-0 fw-semibold"><a className="light-blue-color" onClick={() => navigate("/plan-detail", { state: data })}>{data?.name}</a></p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default PlanDetails