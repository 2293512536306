import React, { useEffect, useState } from 'react'
import { addCommentOnTicket, closeTicket, getBusiness, getProfile, reopenTicket, ticketDetail } from '../../store/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { CalendarClock, Download, MapPin } from 'lucide-react'
import toast from 'react-hot-toast';

const TicketDetail = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const business = useSelector(getBusiness)
    const profile = useSelector(getProfile)
    const location = useLocation()
    const [isOpen, setisOpen] = useState(false)
    const navigate = useNavigate();
    const [ticket, setticket] = useState(null);
    const [message, setmessage] = useState("");

    const getTicketDetail = async () => {
        try {

            const response = await dispatch(ticketDetail(location?.state)).unwrap();
            setticket(response?.data[0])

        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const closeTheTicket = async () => {
        try {

            await dispatch(closeTicket(ticket._id)).unwrap();
            getTicketDetail()
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    const reopenTheTicket = async () => {
        try {

            await dispatch(reopenTicket(ticket._id)).unwrap();
            getTicketDetail()
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        let mount = true;
        if (mount) {
            getTicketDetail()
        }
        return () => {
            mount = false;
        };
    }, [location?.state])

    const handleClick = async () => {
        try {
            let payload = {
                ticketId: ticket._id,
                message
            }
            if (message == "") {
                toast.error("First add comment")
            } else {

                await dispatch(addCommentOnTicket(payload)).unwrap();

                setmessage("")
                getTicketDetail()


            }
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };


    return (
        <>
            <div className="sp-technical-support-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/tickets")}>{t('supportTickets')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('ticket')} {t('detail')}</li>
                    </ol>
                </nav>
                <div className="d-flex align-items-center gap-2 mb-4">
                    <img height="32" src="../assets/images/support-ticket-icon-sp.svg" className="icon-text" />
                    <h2 className="font-size-24 titilium-font fw-bold blue-color mb-0">{t('ticket')} {t('detail')}</h2>
                </div>
                <div className="mb-4 sp-shadow rounded-3 p-5">
                    <div className="row mb-1">
                        <div className="col-md-6 d-flex align-items-center gap-3">
                            <p className="font-size-18 light-blue-color fw-semibold mb-0"><u>{ticket?.ticketNumber}</u></p>
                            <p className="font-size-18 light-blue-color fw-medium mb-0">{ticket?.category?.name}</p>
                        </div>
                        <div className="col-md-6 d-flex align-items-center gap-3 justify-content-end">
                            <div style={{ gap: '3px' }} className="d-flex">
                                <span className={`${ticket?.priority == "low" ? "l-blue-bg" : ticket?.priority == "Medium" ? "l-blue-bg" : "l-blue-bg"}`} style={{ height: '10px', width: '20px' }}></span>
                                <span className={`${ticket?.priority == "low" ? "grey-bg" : ticket?.priority == "Medium" ? "l-blue-bg" : "l-blue-bg"}`} style={{ height: '10px', width: '20px' }}></span>
                                <span className={`${ticket?.priority == "low" ? "grey-bg" : ticket?.priority == "Medium" ? "grey-bg" : "l-blue-bg"}`} style={{ height: '10px', width: '20px' }}></span>
                            </div>
                            <span className={`btn sp-button py-2 ${ticket?.status == "Open" ? "sp-green-button" : "sp-red-button"}`}>{ticket?.status}</span>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <div className="d-flex align-items-center gap-2 mb-2">
                                <MapPin size={20} className="black-color" />
                                <p className="font-size-16 black-color fw-semibold mb-0">{ticket?.location?.name}</p>
                            </div>
                            <p className="font-size-16 grey-color mb-0">{ticket?.description}</p>
                        </div>
                        <div className="col-md-6 d-flex justify-content-end">
                            <div className="d-flex align-items-center gap-1">
                                <CalendarClock className='black-color' />
                                <div>
                                    <p className="font-size-16 grey-color mb-0">{t('createdOn')} {moment(ticket?.createdAt).format("LL")}</p>
                                    <p className="font-size-16 grey-color mb-0"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="l-blue-bg px-4 py-3 d-inline-block rounded-3">
                                <p className="font-size-14 white-color mb-0 fw-semibold">{t('TicketGeneratedBy')} {ticket?.userId?.name}</p>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex justify-content-end">
                            <div className="d-flex align-items-center gap-1 cursor-pointer" onClick={() => setisOpen(true)}>
                                <Download className='black-color' />
                                <p className="font-size-16 black-color mb-0"><u>{ticket?.image}</u></p>
                            </div>
                            {isOpen && (
                                <Lightbox
                                    mainSrc={`${process.env.REACT_APP_IMGURL}/${ticket?.image}`}
                                    onCloseRequest={() => setisOpen(false)}
                                    onError={(error) => console.error('Lightbox Error:', error)}
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div className="sp-ticket-container">
                    {ticket?.comments?.map((data, i) => (<>
                        {data?.assignUser && data?.message ? <>
                            <div className='sp-ticket-single-1'>
                                <p className="font-size-16 light-blue-color mb-0 fw-semibold">{data?.commentedUserId?.name}</p>
                                <p className="font-size-16 black-color mb-0">{t('Message')}: {data?.message}</p>
                                <span className="ticket-date font-size-12 black-white mb-0">{moment(data?.date).format("MMMM Do YYYY, h:mm a")}</span>
                                <p className="font-size-16 color-white mb-0 fw-semibold">{t('TicketAssignto')} {data?.assignUser?.name}</p>
                            </div>

                        </> : data?.assignUser ? <>
                            <div className="px-5 py-2 d-inline-block rounded-3" style={{ "backgroundColor": "rgb(70 135 234 / 50%)" }}>
                                <p className="font-size-16 color-white mb-0 fw-semibold">{t('TicketAssignto')} {data?.assignUser?.name}</p>
                                <p className="font-size-12 black-white mb-0">{moment(data?.date).format("MMMM Do YYYY, h:mm a")}</p>
                            </div>
                        </> : data?.message ? <>
                            <div className='sp-ticket-single-2'>
                                <p className="font-size-16 light-blue-color mb-0 fw-semibold">{data?.commentedUserId?.name}</p>
                                <p className="font-size-16 black-color mb-0">{t('message')}: {data?.message}</p>
                                <span className="ticket-date font-size-12 black-white mb-0">{moment(data?.date).format("MMMM Do YYYY, h:mm a")}</span>
                            </div>
                        </> : <></>}</>
                    ))}
                </div>

                {ticket?.status == "Open" || ticket?.status == "Resolved" ?
                    <div>

                        <div class="form-floating sp-textarea mb-3">
                            <textarea class="form-control" placeholder="Leave a comment here" id="Description" style={{ height: '100px' }} value={message} onChange={(e) => setmessage(e.target.value)}></textarea>
                            <label for="Description">{t('description')}</label>
                        </div>
                        <div className="d-flex gap-2">
                            <button class="btn sp-button sp-blue-button" onClick={handleClick}>{t('add')} {t('comment')}</button>
                            {ticket?.status == "Resolved" && < button class="btn sp-button sp-blue-button" onClick={closeTheTicket}>{t('closed')}</button>}
                            {profile?._id == ticket?.assignedUser ? ticket.status == "Resolved" ? <button class="btn sp-button sp-blue-button" onClick={reopenTheTicket} >{t('reopen')}</button> : ticket.status == "Closed" ? <></> : <button class="btn sp-button sp-blue-button"  >{t('resolved')}</button> : <></>}
                        </div>

                    </div> :
                    <></>}

            </div>
        </>
    )
}

export default TicketDetail