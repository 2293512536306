import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { changePackageStatus } from '../../store/slices/userSlice';
import { Download } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const VisitingDetail = () => {

    const { t } = useTranslation();
    const location = useLocation()
    const navigate = useNavigate();
    const [detail, setdetail] = useState(location?.state);




    return (
        <>
            <div className="sp-customer-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/visitings")}>{t('visitings')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('visitor')} {t('detail')}</li>
                    </ol>
                </nav>
                <div className="d-flex align-items-center gap-2 mb-4">
                    <img height="32" src="../assets/images/visiting-icon-sp.svg" className="icon-text" />
                    <h2 className="font-size-24 titilium-font fw-bold black-color mb-0">{t('visitor')} {t('detail')}</h2>
                </div>
                <div className="sp-shadow sp-radius-20 white-bg p-3 mb-3">
                    <div className="row">
                        <div className="col-md-6">
                            <div class="mb-3 p-2">
                                <p className="font-size-16 black-color mb-0">{t('type')}</p>
                                <p className="font-size-18 light-blue-color fw-semibold mb-0">{detail?.type}</p>
                            </div>
                            <div class="mb-3 l-grey-bg p-2 rounded">
                                <p className="font-size-16 black-color mb-0">{t('location')}</p>
                                <p className="font-size-18 light-blue-color fw-semibold mb-0">{detail?.spaceId?.name}</p>
                            </div>
                            <div class="mb-3 p-2">
                                <p className="font-size-16 black-color mb-0">{t('purpose')}</p>
                                <p className="font-size-18 light-blue-color fw-semibold mb-0">{detail?.purpose}</p>
                            </div>
                            <div class="l-grey-bg p-2 rounded">
                                <p className="font-size-16 black-color mb-0">{t('visit')} {t('date')}</p>
                                <p className="font-size-18 light-blue-color fw-semibold mb-0">{moment(detail?.dateTime).format("MMMM Do YYYY")}</p>
                            </div>
                            <div class="l-grey-bg p-2 rounded">
                                <p className="font-size-16 black-color mb-0">{t('visit')} {t('time')}</p>
                                <p className="font-size-18 light-blue-color fw-semibold mb-0">{detail?.time}</p>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex justify-content-end">
                            <img className="sp-qr-qode" src={`${detail?.qrCode}`} alt="" />
                        </div>
                    </div>
                </div>
                <div className="sp-shadow sp-radius-20 white-bg p-3 mb-4">
                    {detail?.type == "Single" &&
                        <>
                            <div class="mb-3">
                                <p className="font-size-16 black-color mb-0">{t('visitor')} {t('name')}</p>
                                <p className="font-size-18 light-blue-color fw-semibold mb-0">{detail?.name}</p>
                            </div>

                            <div class="mb-3">
                                <p className="font-size-16 black-color mb-0">{t('visitor')} {t('phone')}</p>
                                <p className="font-size-18 light-blue-color fw-semibold mb-0">{detail?.phone}</p>
                            </div>
                            <div class="mb-3">
                                <p className="font-size-16 black-color mb-0">{t('company')}</p>
                                <p className="font-size-18 light-blue-color fw-semibold mb-0">{detail?.company}</p>
                            </div>
                            <div class="mb-3">
                                <p className="font-size-16 black-color mb-0">{t('IDCard')}</p>
                                <p className="font-size-18 light-blue-color fw-semibold mb-0">{detail?.idCard}</p>
                            </div>
                            <div class="mb-3">
                                <p className="font-size-16 black-color mb-0">{t('address')}</p>
                                <p className="font-size-18 light-blue-color fw-semibold mb-0">{detail?.address}</p>
                            </div>
                        </>
                    }
                    <div class="mb-3">
                        <p className="font-size-16 black-color mb-0">{t('description')}</p>
                        <p className="font-size-18 light-blue-color fw-semibold mb-0">{detail?.description}</p>
                    </div>

                    {detail?.type == 'Single' ?
                        <>
                            <div class="mb-3">
                                <p className="font-size-16 black-color mb-0">{t('checkIn')}</p>
                                <p className="font-size-18 light-blue-color fw-semibold mb-0">{detail?.checkIn ? detail?.checkIn : "-"}</p>
                            </div>

                            <div class="mb-3">
                                <p className="font-size-16 black-color mb-0">{t('checkOut')}</p>
                                <p className="font-size-18 light-blue-color fw-semibold mb-0">{detail?.checkOut ? detail?.checkOut : "-"}</p>
                            </div>
                        </> :
                        <>
                            <div class="mb-3">
                                <p className="font-size-16 black-color mb-0">{t('checkIns')}</p>
                                <p className="font-size-18 light-blue-color fw-semibold mb-0">{detail?.multiCheckIns ? detail.multiCheckIns.map((data, index) => <span key={index}>{data} ,</span>) : <></>} </p>
                            </div>

                            <div class="mb-3">
                                <p className="font-size-16 black-color mb-0">{t('total')} {t('Check Ins')}</p>
                                <p className="font-size-18 light-blue-color fw-semibold mb-0">{detail?.multiCheckIns.length > 0 ? detail?.multiCheckIns.length : 0}</p>
                            </div>
                        </>}

                    <div class="mb-3">
                        <p className="font-size-16 black-color mb-0">{t('status')}</p>
                        <p className="font-size-18 light-blue-color fw-semibold mb-0">{detail?.isCompleted ? "Completed" : "Not Completed"}</p>
                    </div>

                    <div class="mb-3">
                        <p className="font-size-16 black-color mb-0">{t('QRCODE')}</p>
                        <p className="font-size-18 light-blue-color fw-semibold mb-0"><a href={`${detail?.qrCode}`} target="_blank" download ><Download stroke="#3A5CD6" /></a></p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default VisitingDetail