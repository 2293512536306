import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { changePackageStatus, getCountry } from '../../store/slices/userSlice';
import { useTranslation } from 'react-i18next';
import { DateRange, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

const MailAndPackagesDetail = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()

    const [detail, setdetail] = useState(location?.state);
    const [userStatus, setuserStatus] = useState("");
    const [pickTimeAndDate, setpickTimeAndDate] = useState("");
    const [listOfCountry, setlistOfCountry] = useState(null);

    const [country, setcountry] = useState(null);
    const [companyName, setcompanyName] = useState(null);
    const [phone, setphone] = useState(null);
    const [address, setaddress] = useState(null);
    const [city, setcity] = useState(null);
    const [postCode, setpostCode] = useState(null);
    const [time, settime] = useState(null);
    const [enableDaysForSingleCalender, setenableDaysForSingleCalender] = useState(null);


    const allCountry = async () => {
        try {

            const response = await dispatch(getCountry()).unwrap();
            setlistOfCountry(response?.data)
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    useEffect(() => {
        setenableDaysForSingleCalender(detail?.spaces?.days)
    }, [detail])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let payload = {
                id: detail._id,
                pickUpDateTime: moment(pickTimeAndDate).format("YYYY-MM-DD"),
                userStatus: userStatus,
                companyName,
                phone,
                address,
                city,
                country,
                postCode,
                time,
                spaceId: detail?.spaces?._id

            }

            await dispatch(changePackageStatus(payload)).unwrap();
            navigate("/mail-and-packages")



        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    console.log(detail)

    // const isDisabled = (day) => {
    //     if (!enableDaysForSingleCalender) {
    //         return true; // Disable all days if enableDaysForSingleCalender is not set
    //     }

    //     // Disable past dates
    //     const today = new Date();
    //     today.setHours(0, 0, 0, 0);
    //     if (day < today) {
    //         return true;
    //     }

    //     // Disable specific days
    //     const dayName = day.toLocaleDateString('en-US', { weekday: 'short' });

    //     return !enableDaysForSingleCalender.includes(dayName);
    // };

    const isDisabled = (day) => {
        if (!enableDaysForSingleCalender) {
            return false;
        }
        const dayName = day.toLocaleDateString('en-US', { weekday: 'short' });
        return !enableDaysForSingleCalender.includes(dayName);
    };


    const statuss = ["Pick up", "Mail Forwarding", "Discard"]

    const [showDropdown, setShowDropdown] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);

    const [searchValue, setSearchValue] = useState('');

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        setHighlightedIndex(-1);
        if (value.length > 0) {
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
        }
    };
    const handleOptionClick = (data) => {
        setSearchValue(data?.name);
        setcountry(data?.name);
        setShowDropdown(false);
    };

    const filteredCountries = listOfCountry?.filter((data) =>
        data?.name?.toLowerCase().includes(searchValue?.toLowerCase())
    );

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            // Move to the next item in the list
            setHighlightedIndex((prevIndex) =>
                prevIndex < filteredCountries.length - 1 ? prevIndex + 1 : prevIndex
            );
        } else if (e.key === 'ArrowUp') {
            // Move to the previous item in the list
            setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        } else if (e.key === 'Enter') {
            // Select the highlighted item when Enter key is pressed
            if (highlightedIndex !== -1) {
                handleOptionClick(filteredCountries[highlightedIndex]);
            }
        }
    };


    useEffect(() => {
        allCountry()
    }, [])

    useEffect(() => {
        if (detail) {
            setuserStatus(detail?.userStatus)
            setcompanyName(detail?.mailforwarding?.companyName)
            setphone(detail?.mailforwarding?.phone)
            setaddress(detail?.mailforwarding?.address)
            setcity(detail?.mailforwarding?.city)
            setcountry(detail?.mailforwarding?.country)
            setSearchValue(detail?.mailforwarding?.country)
            setpostCode(detail?.mailforwarding?.postCode)
        }
    }, [detail]);

    const handlePhoneChange = (e) => {
        let input = e.target.value;
    
        if (!input) {
          setphone('');
          return;
        }
        input = input.replace(/[^0-9]/g, '');
        const formattedPhone = input.length > 0 ? `+${input}` : '+';
        setphone(formattedPhone);
      };

      console.log(phone, "phone");

    return (
        <>
            <div className="sp-technical-support-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item"><a onClick={() => navigate("/mail-and-packages")}>{t('mailAndPackages')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('mailAndPackages')} {t('detail')}</li>
                    </ol>
                </nav>

                <div className="d-flex align-items-center gap-2 mb-4">
                    <img height="32" src="../assets/images/packages-icon-sp.svg" className="icon-text" />
                    <h2 className="font-size-24 titilium-font fw-bold black-color mb-0">{t('mailAndPackages')} {t('detail')}</h2>
                </div>

                <div className="sp-shadow sp-radius-20 white-bg p-4">
                    <p className="font-size-16 light-blue-color mb-3 fw-bold">#{detail?.trackingNumber}</p>
                    <div className="row gy-3 mb-4">
                        <div className="col-md-3">
                            <div className="d-flex gap-2">
                                <img width={36} src="../assets/images/date-icon-sp.svg" alt="" />
                                <div>
                                    <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>{t('received')} {t('date')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{moment(detail?.createdAt).format("MMMM Do YYYY")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="d-flex gap-2">
                                <img width={36} src="../assets/images/sender-address-icon-sp.svg" alt="" />
                                <div>
                                    <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>{t('sender')} {t('address')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{detail?.location?.address}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="d-flex gap-2">
                                <img width={36} src="../assets/images/received-by-icon-sp.svg" alt="" />
                                <div>
                                    <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>{t('receivedBy')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{detail?.receivedBy?.name}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="d-flex gap-2">
                                <img width={36} src="../assets/images/postcode-icon-sp.svg" alt="" />
                                <div>
                                    <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>{t('postcode')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{detail?.location?.postCode}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="d-flex gap-2">
                                <img width={36} src="../assets/images/type-icon-sp.svg" alt="" />
                                <div>
                                    <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>{t('type')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{detail?.type}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="d-flex gap-2">
                                <img width={36} src="../assets/images/city-icon-sp.svg" alt="" />
                                <div>
                                    <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>{t('city')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{detail?.location?.city}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="d-flex gap-2">
                                <img width={36} src="../assets/images/city-icon-sp.svg" alt="" />
                                <div>
                                    <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>{t('country')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{detail?.location?.country}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="d-flex gap-2">
                                <img width={36} src="../assets/images/status-icon-sp.svg" alt="" />
                                <div>
                                    <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>{t('status')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{detail?.finalStatus ? detail?.finalStatus : "Not Completed"}</p>
                                </div>
                            </div>
                        </div>
                        {detail?.pickUpDateTime && <div className="col-md-3">
                            <div className="d-flex gap-2">
                                <img width={36} src="../assets/images/date-icon-sp.svg" alt="" />
                                <div>
                                    <p className="font-size-18 color-black fw-semibold mb-0" style={{ lineHeight: 1 }}>{t('pickup')} {t('date')}</p>
                                    <p className="font-size-16 light-blue-color mb-0">{moment(detail?.pickUpDateTime).format("MMMM Do YYYY")}</p>
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div class="mb-3">
                                <label class="blue-color fw-semibold mb-2">{t('status')}</label>
                                <div className="d-flex gap-3 flex-wrap">
                                    {statuss?.map((data, i) => (
                                        <>
                                            <input disabled={detail?.userStatus ? true : false} class="btn-check" defaultValue={detail?.userStatus} checked={detail?.userStatus?.includes(data)} type="radio" name="status" id={data} key={i} onChange={() => setuserStatus(data)} />
                                            <label class="btn sp-check-select" for={data}>{data}</label>
                                        </>
                                    ))}
                                </div>
                            </div>
                            {userStatus == "Pick up" &&
                                <div class="form-floating sp-input mb-3">
                                    {!detail.userStatus && enableDaysForSingleCalender && <><DayPicker
                                        mode="single"

                                        selected={pickTimeAndDate}
                                        onSelect={setpickTimeAndDate}
                                        disabled={(day) => isDisabled(day)}
                                    />
                                        <input type="time" class="form-control" id="floatingInput" placeholder="date"
                                            autocomplete="date" value={time} onChange={(e) => settime(e.target.value)} />

                                    </>
                                    }
                                </div>
                            }
                            {userStatus == "Mail Forwarding" && <>
                                <div class="form-floating sp-input mb-3">
                                    <input disabled={detail?.userStatus ? true : false} type="text" maxLength={45} class="form-control" id="floatingInput" placeholder="Company Name" value={companyName} onChange={(e) => setcompanyName(e.target.value)} />
                                    <label for="floatingInput">{t('company')} {t('name')}</label>
                                </div>
                                <div class="form-floating sp-input mb-3">
                                    <input disabled={detail?.userStatus ? true : false} type="tel" maxLength={13} class="form-control" id="floatingInput" placeholder="Phone" value={phone} onChange={handlePhoneChange} />
                                    <label for="floatingInput">{t('phone')}</label>
                                </div>
                                <div class="form-floating sp-input mb-3">
                                    <input disabled={detail?.userStatus ? true : false} type="text" class="form-control" id="floatingInput" placeholder="Address" value={address} onChange={(e) => setaddress(e.target.value)} />
                                    <label for="floatingInput">{t('address')}</label>
                                </div>
                                <div class="form-floating sp-input mb-3">
                                    <input disabled={detail?.userStatus ? true : false} type="text" maxLength={20} class="form-control" id="floatingInput" placeholder="City" value={city} onChange={(e) => setcity(e.target.value)} />
                                    <label for="floatingInput">{t('city')}</label>
                                </div>
                                <div class="form-floating sp-input">
                                    <input disabled={detail?.userStatus ? true : false} type="text" class="form-control" id="country" maxLength={120} placeholder="country" onKeyDown={handleKeyDown} onChange={(e) => handleInputChange(e)} value={searchValue} />
                                    <label for="country">{t('country')}</label>
                                </div>
                                {showDropdown && (
                                    <div className="dropdown sp-select-dropdown">
                                        <ul className="dropdown-menu show" style={{ width: '100%' }}>
                                            {listOfCountry?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.map((data, index) => (
                                                <li key={index} onClick={() => handleOptionClick(data)} className={highlightedIndex === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
                                            ))}
                                            {listOfCountry?.length > 0 && listOfCountry?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.length === 0 && (
                                                <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
                                            )}
                                        </ul>
                                    </div>
                                )}
                                <div class="form-floating sp-input mb-3 mt-3">
                                    <input disabled={detail?.userStatus ? true : false} type="text" class="form-control" id="floatingInput" placeholder="postcode" value={postCode} onChange={(e) => setpostCode(e.target.value)} />
                                    <label for="floatingInput">{t('postcode')}</label>
                                </div>
                            </>}

                            {!detail?.userStatus ? <div className="text-center">
                                <button class="btn sp-button sp-blue-button" onClick={handleSubmit}>{t('save')}</button>
                            </div> : <></>}
                        </div>
                        <div className="col-md-6">
                            <div>
                                <p className="font-size-16 blue-color fw-semibold mb-2">{t('image')}</p>
                                <img class="" alt="avatar" className='img-fluid rounded-4' src={`${process.env.REACT_APP_IMGURL}/${detail?.attachment[0]}`} />
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MailAndPackagesDetail