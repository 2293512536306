import React, { useEffect, useState } from 'react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Heart } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { addLikeAndUnlike, getAllFavortiePlans, listOfPlans } from '../../store/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { getSocket } from '../../store/slices/socketSlice';
import CardSkeleton from '../../components/CardSkeleton';
import { useTranslation } from 'react-i18next';



const FavoritePlans = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch()
    const [plans, setplans] = useState()
    const navigate = useNavigate()
    const socket = useSelector(getSocket);
    const [load, setload] = useState(false);



    const getAllPlans = async () => {
        try {
            setload(true);
            const response = await dispatch(getAllFavortiePlans()).unwrap();
            //    console.log(response?.data)
            setplans(response?.data)
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };
 console.log(plans)


    useEffect(() => {
        let mount = true;
        if (mount) {
            getAllPlans();
        }
        return () => {
            mount = false;
        };
    }, [])

    const likeUnLikePlan = async (data) => {
        try {
            let payload = {
                planId: data?._id,
                behave: data?.behave
            }
            await dispatch(addLikeAndUnlike(payload)).unwrap();
            getAllPlans()
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

 
    return (
        <>
        <div className="sp-bookings-page pb-5 px-md-5 px-3">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb sp-breadcrumb">
                    <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{t('favorites')}</li>
                </ol>
            </nav>
            <div className="d-flex align-items-center gap-2 mb-4">
            <img height="32" src="../assets/images/favourite-icon-sp.svg" className="icon-text" />
            <h2 className="font-size-24 titilium-font fw-bold black-color mb-0">{t('favorites')}</h2>
            </div>
            <div className="sp-shadow sp-radius-20 white-bg p-3">
            <div className="row gy-4">
                {load ? new Array(8).fill(null).map((_, index) => (<CardSkeleton key={index} column="col-md-3" />)) : plans?.map((data, index) => (
                    <div className="col-md-3 sp-cards" key={index} >
                        <div className="sp-card-body">
                            <a onClick={() => navigate("/plan-detail", { state: data })}>
                            <img alt="" src={`${process.env.REACT_APP_IMGURL}/${data?.image[0]}`} className="sp-card-image w-100" />
                            </a>

                            <div className="d-flex justify-content-between align-items-center top-box">
                                <span className="font-size-14 py-1 px-3 text-capitalize white-color fw-medium" style={{ backgroundColor: 'var(--color5)' }}>{data?.type}</span>
                                {data?.isLiked ? <Heart className="cursor-pointer" fill="var(--color5" stroke="#FFFFFF" onClick={() => likeUnLikePlan(data)} /> : <Heart className="cursor-pointer" fill="#3131314D" stroke="#ffffff" onClick={() => likeUnLikePlan(data)} />}
                            </div>
                        </div>
                        <div className="sp-card-footer cursor-pointer">
                                <p className="font-size-16 black-color mb-0 titilium-font">{data?.spaceDetail?.name}</p>
                                <p className="font-size-18 light-blue-color mb-0 fw-semibold"><a onClick={() => navigate("/plan-detail", { state: data })}>{data?.name}</a></p>
                        </div>
                    </div>
                ))}
            </div>
            </div>
        </div>
        </>
    )
}

export default FavoritePlans