import React, { useEffect, useState } from 'react';
import { GoogleMap, MarkerF } from '@react-google-maps/api';


const EventMap = ({ lat, long }) => {
    const mapStyles = {
        height: '400px',
        width: '100%',
        borderRadius: '20px'
    };

    const [marker, setMarker] = useState({ lat: lat, lng: long })


    const center = {
        lat: lat || 0,
        lng: long || 0,
    };

    useEffect(() => {
        setMarker({ lat: lat, lng: long });
    }, [lat, long])

    return (
        <div>
            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={15}
                center={center}

            >
                <MarkerF position={marker} />
            </GoogleMap>


        </div>
    );
};

export default EventMap;
