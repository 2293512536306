import React, { useEffect, useState } from 'react'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBookingList, getBusiness, getMemberTickets } from '../../store/slices/userSlice';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Download, Eye, Pencil, Search, X } from 'lucide-react';


const Tickets = () => {
    const { t } = useTranslation();

    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const business = useSelector(getBusiness)

    const [tickets, settickets] = useState()
    const [load, setload] = useState(false);
    const [filterType, setfilterType] = useState("all")
    console.log(tickets)

    const ticketList = async () => {
        try {
            setload(true);
            const response = await dispatch(getMemberTickets(business?.businessId)).unwrap();

            settickets(response?.data)
            setload(false);

        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };


    useEffect(() => {
        let mount = true;
        if (mount) {
            ticketList();
        }
        return () => {
            mount = false;
        };
    }, [business])

    return (
        <>
            <div className="sp-technical-support-page pb-5 px-md-5 px-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb sp-breadcrumb">
                        <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{t('supportTickets')}</li>
                    </ol>
                </nav>

                <div className="row gy-3 mb-4 align-items-center">
                    <div className="col-md-3 d-flex align-items-center gap-2">
                        <img height="32" src="../assets/images/support-ticket-icon-sp.svg" className="icon-text" />
                        <h2 className="font-size-24 titilium-font fw-bold black-color mb-0">{t('supportTickets')}</h2>
                    </div>
                    <div className="col-md-6 order-md-2 order-3">
                    </div>
                    <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
                        <button class="btn sp-button sp-blue-button" onClick={() => navigate("/add-ticket")}>{t('add')} {t('supportTickets')}</button>
                    </div>
                </div>

                <div className="sp-shadow sp-radius-20 white-bg">
                    <div class="table-responsive">
                        <table class="table sp-table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">{t('ticketNo')}</th>
                                    <th scope="col">{t('priority')}</th>
                                    <th scope="col">{t('category')}</th>
                                    <th scope="col">{t('assignedTo')}</th>
                                    <th style={{ width: '100px' }} scope="col">{t('actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {load ? new Array(6).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : tickets?.map((data, i) => (
                                    <tr>
                                        <td>{data?.ticketNumber}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}><span className={`btn sp-button py-2 ${data?.priority == "High" ? "sp-red-button" : data.priority == "Medium" ? "sp-grey-button" : "sp-green-button"}`}>{data?.priority}</span></td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.category?.name}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>{data?.assignedUser?.name}</td>
                                        <td style={{ 'verticalAlign': 'middle' }}>
                                            <div className="d-flex align-items-center gap-2">
                                                <div onClick={() => navigate("/ticket-detail", { state: data._id })} className={`sp-action-icons`}><Eye width="18" /></div>
                                                <div className={`sp-action-icons`}><X width="18" /></div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Tickets