import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getNotifications, getProfile, getUserToken, signOut } from '../../store/slices/userSlice'
import i18n from '../i18n';
import LanguageSwitcher from './LanguageSwitcher';
import moment from 'moment';
import { getSocket } from '../../store/slices/socketSlice';

const NavBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const authToken = useSelector(getUserToken)
  const profile = useSelector(getProfile)
  const socket = useSelector(getSocket)
  const [isNotify, setisNotify] = useState(false)
  const [userDetail, setuserDetail] = useState("")
  const [id, setid] = useState("")
  const [notifications, setNotifications] = useState([])

  const listOfNotifications = async () => {
    try {


      const response = await dispatch(getNotifications()).unwrap();
      setisNotify(false)
      setNotifications(response?.data)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {

    // Listen for 'checknotification' event
    socket?.on('checknotification', (data) => {

      socket?.emit("getNotificationStatus", {
        data: data?.user
      });

      setuserDetail(data?.user)

    });

    return () => {
      socket?.disconnect();
    };
  }, [socket]);

  const callMessage = () => {
    socket?.on("response", (data) => {
      if (data?.object_type == "check_all_notification") {
        console.log(data)
        setid(data?.data)
        setisNotify(true)

      }
    });
  };


  useEffect(() => {
    callMessage()
  }, [socket, userDetail])

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 10;
      setIsScrolled(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const currentLanguage = i18n.language;

  return (
    <>
      <header className={`${isScrolled ? 'sp-navbar white-bg' : 'sp-navbar'}`}>
        <div className="row px-2 gy-4">
          <div className="col-md-6 d-flex align-items-center gap-4">
            <span>
            <img onClick={() => navigate("/dashboard")} className="sp-logo cursor-pointer" width="320" src={`../assets/images/logo-${currentLanguage === 'ar' ? 'arabic-' : ''}new.svg`} />
            <p style={{marginTop:'-10px', marginRight:'10px'}} className="text-end light-blue-color font-size-16 text-uppercase mb-0">{t('members')}</p>
            </span>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-md-end justify-content-center" style={{ "gap": "25px" }}>
            <LanguageSwitcher />
            <img class="" alt="messages" src="./assets/images/message-icon-sp.svg" className='cursor-pointer' onClick={() => navigate("/messages")} />
            <div class="dropdown sp-notification-list">
              <a class="" onClick={listOfNotifications} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {isNotify && profile?._id === id ? <img class="" alt="messages" src="./assets/images/notification-fill-icon-sp.svg" /> : <img class="" alt="messages" src="./assets/images/notification-icon-sp.svg" />}
              </a>

              <ul class="dropdown-menu">
                {notifications?.slice(0, 10)?.map((data, index) => (
                  <li class="d-flex align-items-center gap-2 sp-notification-list-single mb-2" key={index}>
                    <img src={`${process.env.REACT_APP_IMGURL}/${data?.otherUser?.imageName}`} alt="Image" className="rounded-circle" />
                    <div className="flex-shrink-1">
                      <div className="d-flex justify-content-between">
                        <p className="font-size-14 light-blue-color fw-semibold mb-0">{data?.otherUser?.name}</p>
                        <p className="font-size-12 grey-color mb-0">{moment(data.createdAt).format("MMMM Do YYYY")}</p>
                      </div>
                      <p className="font-size-12 black-color mb-0">{data?.body}</p>
                    </div>
                  </li>
                ))}
                <li className="text-center light-blue-color"><a onClick={() => navigate("/notification")}>See All</a></li>
              </ul>
            </div>
            <div class="d-flex align-items-center gap-3 sp-profile-img">
              <div className="text-end">
                <p className="font-size-14 titilium-font dark-blue-color mb-0 fw-semibold text-capitalize">{profile?.name}</p>
                <p className="font-size-14 grey-blue-color mb-0">Member</p>
              </div>
              {profile.imageName ? <img class="rounded-circle" alt="avatar" src={`${process.env.REACT_APP_IMGURL}/${profile?.imageName}`} /> : <img class="rounded-circle" alt="avatar" src='https://via.placeholder.com/65x65' />}
            </div>
            {/* <div class="dropdown sp-nav-dropdown-link">
              <a class="" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img class="" alt="dropdown" src="./assets/images/dropdown_icon.svg" />
              </a>

              {/* <ul class="dropdown-menu">
                <li><a class="dropdown-item">Profile Setting</a></li>
                <li><a class="dropdown-item">Logout</a></li>
              </ul>
            </div> */}
          </div>
        </div>
      </header>
    </>
  )
}

export default NavBar