

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { signinUser, socialLogin } from "../../store/slices/userSlice";
import Cookies from "js-cookie";


const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isSecureEntry, setisSecureEntry] = useState(true)
    const [user_device_token, setuser_device_token] = useState(true)


    // useEffect(() => {
    //     const data = localStorage.getItem("user_device_token");
    //     setuser_device_token(data)
    // }, [])

    useEffect(() => {
        const data = Cookies.get("user_device_token");
        setuser_device_token(data);
    }, []);

    console.log(user_device_token, "user_device_token")

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let paylaod = {
                email,
                password,
                devicetoken: user_device_token
            }
            const response = await dispatch(signinUser(paylaod)).unwrap();

            console.log("response?.data:", response)
            if (response?.isSingle) {
                navigate("/")
            } else {
                navigate('/select-business', { state: response });
            }
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };



    return (
        <>
            <section class="authentication-bg">
                <div class="container py-5 h-100">
                    <div class="row d-flex justify-content-center align-items-center h-100">
                        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div class="card shadow-sm rounded border-0">
                                <div class="card-body p-5 text-center">
                                    <img onClick={() => navigate("/")} className="mx-auto d-block auth-logo mb-5 cursor-pointer" width="400" src={`${process.env.REACT_APP_FRONTURL}assets/images/logo-new.svg`} />
                                    {/* <div class="d-grid gap-3 mb-3">
                                        <button class="btn social-btn btn-lg sp-shadow" type="button" onClick={(e) => handleGoogleSignIn(e)}><i class="fab fa-google me-2" ></i>Log in with Google</button>
                                        <button class="btn social-btn btn-lg sp-shadow" type="button" onClick={(e) => handleFacebookSignIn(e)}><i class="fab fa-facebook-f me-2"></i>Log in with Facebook</button>
                                    </div>
                                    <p className="font-size-18 dark-blue-color"><b>or</b></p> */}
                                    <form onSubmit={handleSubmit} >
                                        <div class="form-floating sp-input mt-3 mb-3">
                                            <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
                                                onChange={(e) => setEmail(e.target.value)} autocomplete="email" required />
                                            <label for="floatingInput">Email address</label>
                                        </div>
                                        <div class="form-floating sp-input mb-3 ">
                                            <input class="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => setPassword(e.target.value)}
                                                type={isSecureEntry ? "password" : "text"} autocomplete="password" required />
                                            <i onClick={() => { setisSecureEntry((prev) => !prev) }} className={`fa-solid ${isSecureEntry ? "fa-eye" : "fa-eye-slash"} eye-password`}></i>
                                            <label for="floatingPassword">Password</label>
                                        </div>

                                        <div class="row justify-content-between mb-3">
                                            <div class="col-md-4">
                                                <div class="form-check sp-login-checkbox">
                                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                    <label class="form-check-label" for="flexCheckDefault">
                                                        Keep me login
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-4 text-end">
                                                <a onClick={() => navigate("/forget-password")} className="reset-pass">Forgot password?</a>
                                            </div>
                                        </div>


                                        <button class="btn sp-button sp-blue-button mb-4 w-100" type="submit">Log in</button>

                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default Login;
