import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { verifyAccount } from "../../store/slices/userSlice";


const VerifyAccount = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [otp, setOTP] = useState("");


    console.log(location.state)

    const handleKeyPress = (e) => {
        const isValidKey = /^\d$/.test(e.key) || e.keyCode === 8;

        if (!isValidKey) {
            e.preventDefault();
        }
    };

    const handleClick = async (e) => {
        e.preventDefault();

        try {

            let payload = {
                id: location?.state?._id,
                otp
            }
            const response = await dispatch(verifyAccount(payload)).unwrap();

            if (location?.state?.isForget == 1) {

                navigate("/change-password", { state: response?.data })
            } else {
                navigate("/")
            }


        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };
    return (
        <>
            <section class="authentication-bg">
                <div class="container py-5 h-100">
                    <div class="row d-flex justify-content-center align-items-center h-100">
                        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div class="card shadow-2-strong border-0 rounded" >
                                <div class="card-body p-5 text-center">

                                <img onClick={() => navigate("/")} className="mx-auto d-block auth-logo mb-5 cursor-pointer" width="400" src={`${process.env.REACT_APP_FRONTURL}assets/images/logo-new.svg`} />
                                    <img className="mb-3" width="150" src={"../assets/images/otp-verification-sp.svg"} />
                                    <p className="font-size-24 titilium-font fw-bold light-blue-color mb-0">
                                        We Just Sent You A Verification Email
                                    </p>
                                    <p className="font-size-14 color-black mb-4">
                                        Please enter your OTP that you have recive through email.
                                    </p>
                                    <div className="custom-otp-input mb-4">

                                        <input
                                            type="text"
                                            maxLength={6}
                                            onChange={(e) => setOTP(e.target.value)}
                                            onKeyPress={handleKeyPress}
                                        />

                                    </div>
                                    {/* <div class="text-center">
                                        <button class="btn sp-blue-btn sp-btn btn-lg"
                                            onClick={handleClick}
                                        >Verify</button>
                                    </div> */}
                                    <button class="btn sp-button sp-blue-button w-100" onClick={handleClick}>Verify</button>
                                </div>
                                <div class="card-footer sp-auth-card-footer">
                                    <div className="text-center">
                                        <a
                                            className=""
                                        // onClick={handleResend}
                                        >
                                            Didn't receive the code? <b className="light-blue-color">Resend</b>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default VerifyAccount;