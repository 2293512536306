import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import Cookies from 'js-cookie';

const firebaseConfig = {
    apiKey: "AIzaSyCk6pkkFIQSul3lHL-afvuPLGqCSDKYWK0",
    authDomain: "spaces-manager-6fbae.firebaseapp.com",
    projectId: "spaces-manager-6fbae",
    storageBucket: "spaces-manager-6fbae.appspot.com",
    messagingSenderId: "777279650476",
    appId: "1:777279650476:web:80abda5ee50d51ca9dbd09",
    measurementId: "G-J3SSGPS21C"
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const requestForToken = async () => {
    const permission = await Notification.requestPermission()
    console.log(permission)
    if (permission == "granted") {
        const token = await getToken(messaging, {
            vapidKey: 'BNYELHhBWUa_9dAf_MH_oGoQyOH5O7a76sN2WRaGfowA410kukUQ9exwer_eebEUE5rrCsWzhKeAHXjAc-zxRnk'
        })

        
        Cookies.set("user_device_token", token, { expires: 365 }); // Set cookie to expire in 365 days

    }
};



export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log("payload", payload)
            resolve(payload);
        });
    });


 

