import React, { useEffect, useState, useContext } from 'react'
import { context } from '../../context/context';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AlignJustify, ChevronDown, ChevronUp } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { getProfile, getUserToken, signOut } from '../../store/slices/userSlice';

const SideBar = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { toggleButton, SetToggleButton } = useContext(context);

    const dispatch = useDispatch()
    const authToken = useSelector(getUserToken)
    const profile = useSelector(getProfile)
  
    const logout = async () => {
      try {
        await dispatch(signOut()).unwrap();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };

  return (
    <>
    <div className={toggleButton ? "sidebar close" : "sidebar"}>
                <div class="logo-details">
                    <AlignJustify onClick={() => SetToggleButton((prev) => !prev)} stroke='var(--color5)' strokeWidth={1} />
                </div>
                <ul class="nav-links">
                <li className={location.pathname == "/" ? "active" : ""}>
                    <div className="icon-link">
                        <Link to='/'>
                            <img className="" alt="icon" src={`./assets/images/home-icon-sp.svg`} />
                            <span className="link_name">{t('home')}</span>
                        </Link>
                    </div>
                </li>
                <li className={location.pathname == "/bookings" || location.pathname == "/booking-detail" ? "active" : ""}>
                    <div className="icon-link">
                        <Link to='/bookings'>
                            <img className="" alt="icon" src={`./assets/images/bookings-icon-sp.svg`} />
                            <span className="link_name">{t('bookings')}</span>
                        </Link>
                    </div>
                </li>
                <li className={location.pathname == "/locations" || location.pathname == "/plan-detail" ? "active" : ""}>
                    <div className="icon-link">
                        <Link to='/locations'>
                            <img className="" alt="icon" src={`./assets/images/locations-icon-sp.svg`} />
                            <span className="link_name">{t('locations')}</span>
                        </Link>
                    </div>
                </li>
                <li className={location.pathname == "/favorite-plans" ? "active" : ""}>
                    <div className="icon-link">
                        <Link to='/favorite-plans'>
                            <img className="" alt="icon" src={`./assets/images/favourite-icon-sp.svg`} />
                            <span className="link_name">{t('favorite')}</span>
                        </Link>
                    </div>
                </li>
                <li className={location.pathname == "/tickets" ? "active" : ""}>
                    <div className="icon-link">
                        <Link to='/tickets'>
                            <img className="" alt="icon" src={`./assets/images/support-ticket-icon-sp.svg`} />
                            <span className="link_name">{t('supportTickets')}</span>
                        </Link>
                    </div>
                </li>
                <li className={location.pathname == "/print-and-scan" || location.pathname == "/print-and-scan-detail" || location.pathname == "/add-print-scan-request" ? "active" : ""}>
                    <div className="icon-link">
                        <Link to='/print-and-scan'>
                            <img className="" alt="icon" src={`./assets/images/print-scan-icon-sp.svg`} />
                            <span className="link_name">{t('printAndScan')}</span>
                        </Link>
                    </div>
                </li>
                <li className={location.pathname == "/mail-and-packages" || location.pathname == "/mail-and-packages-detail" ? "active" : ""}>
                    <div className="icon-link">
                        <Link to='/mail-and-packages'>
                            <img className="" alt="icon" src={`./assets/images/packages-icon-sp.svg`} />
                            <span className="link_name">{t('mailAndPackages')}</span>
                        </Link>
                    </div>
                </li>
                <li className={location.pathname == "/visitings" || location.pathname == "/add-visiting" || location.pathname == "/visiting-detail" ? "active" : ""}>
                    <div className="icon-link">
                        <Link to='/visitings'>
                            <img className="" alt="icon" src={`./assets/images/visiting-icon-sp.svg`} />
                            <span className="link_name">{t('visitings')}</span>
                        </Link>
                    </div>
                </li>
                <li className={location.pathname == "/profile-setting" ? "active" : ""}>
                    <div className="icon-link">
                        <Link to='/profile-setting'>
                            <img className="" alt="icon" src={`./assets/images/setting-icon-sp.svg`} />
                            <span className="link_name">{t('setting')}</span>
                        </Link>
                    </div>
                </li>
                <li>
                    <div className="icon-link">
                        <Link onClick={logout}>
                            <img className="" alt="icon" src={`./assets/images/logout-icon-sp.svg`} />
                            <span className="link_name">{t('logout')}</span>
                        </Link>
                    </div>
                </li>
                </ul>
            </div>
    </>
  )
}

export default SideBar