import React from 'react'


const BookingConfirmed = () => {
  return (
    <>

      <section className="booking-section py-5">
        <div className="container">
          <div className="text-center mb-4">
            <img className="mx-auto d-block mb-2" width="108" alt="icon" src={`../assets/images/booking-confirmed-icon.svg`} />
            <h3 className="font-size-26 titilium-font black-color mb-0">Payment successful</h3>
            <h2 className="font-size-36 titilium-font bluish-color mb-0 text-uppercase">Booking Confirmed</h2>
          </div>
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="l-grey-bg py-3 px-3 h-100">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <p className="font-size-20 titilium-font light-blue-color mb-0">Member Details</p>
                  <p className="font-size-18 titilium-font black-color mb-0"><u>Edit</u></p>
                </div>
                <div className="row gy-3">
                  <div className="col-6">
                    <p className="font-size-20 titilium-font black-color mb-0 fw-semibold">Name</p>
                    <p className="font-size-18 titilium-font black-color mb-0">John Doe</p>
                  </div>
                  <div className="col-6">
                    <p className="font-size-20 titilium-font black-color mb-0 fw-semibold">Email</p>
                    <p className="font-size-18 titilium-font black-color mb-0">info@mysite.com</p>
                  </div>
                  <div className="col-6">
                    <p className="font-size-20 titilium-font black-color mb-0 fw-semibold">Phone</p>
                    <p className="font-size-18 titilium-font black-color mb-0">+00 000 00000</p>
                  </div>
                  <div className="col-6">
                    <p className="font-size-20 titilium-font black-color mb-0 fw-semibold">Address</p>
                    <p className="font-size-18 titilium-font black-color mb-0">ABC Street City 12345</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="l-grey-bg py-3 px-3">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <p className="font-size-20 titilium-font light-blue-color mb-0">Company Details </p>
                  <p className="font-size-18 titilium-font black-color mb-0"><u>Edit</u></p>
                </div>
                <div className="row gy-3">
                  <div className="col-6">
                    <p className="font-size-20 titilium-font black-color mb-0 fw-semibold">Name</p>
                    <p className="font-size-18 titilium-font black-color mb-0">John Doe</p>
                  </div>
                  <div className="col-6">
                    <p className="font-size-20 titilium-font black-color mb-0 fw-semibold">Email</p>
                    <p className="font-size-18 titilium-font black-color mb-0">info@mysite.com</p>
                  </div>
                  <div className="col-6">
                    <p className="font-size-20 titilium-font black-color mb-0 fw-semibold">Phone</p>
                    <p className="font-size-18 titilium-font black-color mb-0">+00 000 00000</p>
                  </div>
                  <div className="col-6">
                    <p className="font-size-20 titilium-font black-color mb-0 fw-semibold">Address</p>
                    <p className="font-size-18 titilium-font black-color mb-0">ABC Street City 12345</p>
                  </div>
                  <div className="col-6">
                    <p className="font-size-20 titilium-font black-color mb-0 fw-semibold">Vat No.</p>
                    <p className="font-size-18 titilium-font black-color mb-0">GB1234656789</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center gap-2">
            <img className="" width="27" alt="icon" src={`../assets/images/booking-detail-icon.svg`} />
            <h2 className="font-size-36 titilium-font black-color fw-light mb-0">Booking Details</h2>
          </div>
          <div class="table-responsive">
            <table class="table sm-table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Packages</th>
                  <th className="text-center" scope="col">Quantity</th>
                  <th className="text-center" scope="col">Deposit</th>
                  <th className="text-center" scope="col">Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="d-flex gap-3">
                      <img alt="" src="https://cospace.pk/wp-content/uploads/2023/06/CoSpace-4.0-Image-3.jpg" className="sm-detail-image-list" />
                      <div>
                        <div className="d-flex gap-3 align-items-center">
                          <p className="font-size-20 titilium-font black-color fw-bold mb-0">4 Person Meeting Room Plan</p>
                          <i class="bi bi-trash3 light-blue-color font-size-18 d-flex cursor-pointer"></i>
                        </div>
                        <p className="font-size-16 titilium-font black-color mb-0">Duration <span className="light-blue-color">3 hours</span></p>
                        <p className="font-size-16 titilium-font black-color mb-0">Start Date <span className="light-blue-color">Monday, 16 December 2023</span></p>
                        <p className="font-size-16 titilium-font black-color mb-0">Time <span className="light-blue-color"> 1:30 pm - 4:30 pm</span></p>
                        <p className="font-size-16 titilium-font black-color mb-0">person <span className="light-blue-color">4</span></p>
                      </div>
                    </div>
                  </td>
                  <td className="text-center"><p className="font-size-16 titilium-font black-color mb-0">1</p></td>
                  <td className="text-center"><p className="font-size-16 titilium-font black-color mb-0">PKR <b>12,000</b></p></td>
                  <td className="text-center"><p className="font-size-16 titilium-font black-color mb-0">PKR <b>12,000</b></p></td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex gap-3">
                      <img alt="" src="https://cospace.pk/wp-content/uploads/2023/06/CoSpace-4.0-Image-3.jpg" className="sm-detail-image-list" />
                      <div>
                        <div className="d-flex gap-3 align-items-center">
                          <p className="font-size-20 titilium-font black-color fw-bold mb-0">4 Person Meeting Room Plan</p>
                          <i class="bi bi-trash3 light-blue-color font-size-18 d-flex cursor-pointer"></i>
                        </div>
                        <p className="font-size-16 titilium-font black-color mb-0">Duration <span className="light-blue-color">3 hours</span></p>
                        <p className="font-size-16 titilium-font black-color mb-0">Start Date <span className="light-blue-color">Monday, 16 December 2023</span></p>
                        <p className="font-size-16 titilium-font black-color mb-0">Time <span className="light-blue-color"> 1:30 pm - 4:30 pm</span></p>
                        <p className="font-size-16 titilium-font black-color mb-0">person <span className="light-blue-color">4</span></p>
                      </div>
                    </div>
                  </td>
                  <td className="text-center"><p className="font-size-16 titilium-font black-color mb-0 d-flex align-items-center gap-3 justify-content-center">
                    <span><i class="bi bi-plus font-size-20 d-flex cursor-pointer"></i></span> 1 <span><i class="bi bi-dash font-size-20 d-flex cursor-pointer"></i></span></p></td>
                  <td className="text-center"><p className="font-size-16 titilium-font black-color mb-0">PKR <b>12,000</b></p></td>
                  <td className="text-center"><p className="font-size-16 titilium-font black-color mb-0">PKR <b>12,000</b></p></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="border-top pt-4 mb-3" style={{ '--bs-border-color': 'rgba(58, 92, 214, 0.50)' }}>
            <div className="row">
              <div className="col-md-5 offset-md-7">
                <div className="row mb-1">
                  <div className="col-4 align-self-center text-end">
                    <p className="font-size-12 titilium-font black-color mb-0">Subtotal</p>
                  </div>
                  <div className="col-4 text-end">
                    <p className="font-size-16 titilium-font black-color mb-0">PKR <b>12,000</b></p>
                  </div>
                  <div className="col-4 text-end">
                    <p className="font-size-16 titilium-font black-color mb-0">PKR <b>12,000</b></p>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-4 align-self-center text-end">
                    <p className="font-size-12 titilium-font black-color mb-0">Adjustment</p>
                  </div>
                  <div className="col-4 text-end">
                    <p className="font-size-16 titilium-font black-color mb-0">-</p>
                  </div>
                  <div className="col-4 text-end">
                    <p className="font-size-16 titilium-font black-color mb-0">PKR <b>12,000</b></p>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-4 align-self-center text-end">
                    <p className="font-size-12 titilium-font black-color mb-0">20% VAT</p>
                  </div>
                  <div className="col-4 text-end">
                    <p className="font-size-16 titilium-font black-color mb-0">-</p>
                  </div>
                  <div className="col-4 text-end">
                    <p className="font-size-16 titilium-font black-color mb-0">PKR <b>12,000</b></p>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-4 align-self-center text-end">
                    <p className="font-size-12 titilium-font black-color mb-0">Total</p>
                  </div>
                  <div className="col-4 text-end">
                    <p className="font-size-16 titilium-font black-color mb-0">PKR <b>12,000</b></p>
                  </div>
                  <div className="col-4 text-end">
                    <p className="font-size-16 titilium-font black-color mb-0">PKR <b>12,000</b></p>
                  </div>
                </div>
                <h3 className="font-size-26 titilium-font black-color text-end mb-4">Payable Amount <b>136,000</b></h3>

                <div >
                  <h4 className="font-size-20 titilium-font light-blue-color text-end">Payment Method</h4>
                  <div className='d-flex sm-payment-select justify-content-end'>
                    <input type="radio" name="payment-method" class="btn-check" id="cash" disabled />
                    <label class="btn d-flex gap-2 align-items-center w-50" for="cash">
                      <i class="bi bi-cash black-color font-size-26 d-flex"></i>
                      <span className="font-size-20 titilium-font">Cash</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  )
}

export default BookingConfirmed