import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deleteNotification, getAllPaymentMethods, getBusiness, getByBusinessData, getNotificationDetail, getNotifications, getProfile, setPaymentMethodForBusiness, signOut } from '../../store/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { context } from '../../context/context';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { getSocket } from '../../store/slices/socketSlice';
import moment from 'moment';
import { X } from 'lucide-react';

const Notification = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(getProfile)
  const business = useSelector(getBusiness)
  const navigate = useNavigate();
  const socket = useSelector(getSocket);
  const [notifications, setNotifications] = useState([])
  const [isNotify, setisNotify] = useState(false)
  const [userDetail, setuserDetail] = useState("")
  const [id, setid] = useState("")

  const listOfNotifications = async () => {
    try {


      const response = await dispatch(getNotificationDetail()).unwrap();
      setisNotify(false)
      setNotifications(response?.data)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const handleSubmit = async (e, id) => {
    e.preventDefault()
    try {

      console.log(id)



      await dispatch(deleteNotification(id)).unwrap();

      setNotifications(
        notifications?.filter((data) => data?._id !== id)
      )
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {
    let mount = true;
    if (mount) {
      listOfNotifications();
    }
    return () => {
      mount = false;
    };
  }, [])
  return (
    <>
      <div className="sp-news-management-page pb-5 px-md-5 px-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb sp-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item active" aria-current="page">Notification</li>
          </ol>
        </nav>
        <div className="d-flex align-items-center gap-2 mb-4">
          <img height="32" src="./assets/images/notification-icon-sp.svg" className="icon-text" />
          <h2 className="font-size-24 titilium-font fw-bold black-color mb-0">Notification</h2>
        </div>
        <div className="sp-notification-list">
          {notifications?.slice(0, 10)?.map((data, index) => (
            <div class="d-flex align-items-center gap-3 sp-notification-single mb-2" key={index}>
              <div><img src={`${process.env.REACT_APP_IMGURL}/${data?.otherUser?.imageName}`} alt="Image" className="rounded-circle sp-user-profile-80" /></div>
              <div className="flex-shrink-1 w-100">
                <p className="font-size-22 light-blue-color fw-semibold mb-0">{data?.otherUser?.name}</p>
                <p className="font-size-16 black-color mb-0">{data?.body}</p>
              </div>
              <div style={{ width: '100px' }} className="d-flex flex-column align-items-center gap-2">
                <p className="font-size-12 grey-color mb-0">{moment(data.createdAt).format("MMMM Do YYYY")}</p>
                <X className="black-color cursor-pointer" onClick={(e) => handleSubmit(e, data?._id)} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Notification