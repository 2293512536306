import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signUp } from "../../store/slices/userSlice";



const SignUp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [id, setid] = useState("");

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");

    const [password, setPassword] = useState("");
    const [confirmPassword, setconfirmPassword] = useState("");
    const [tc, settc] = useState(false);
    const [isSecureEntry, setisSecureEntry] = useState(true)
    const [isSecureEntry1, setisSecureEntry1] = useState(true)



    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            let paylaod = {
                name,
                email,
                password,
                confirmPassword
            }
            const response = await dispatch(signUp(paylaod)).unwrap();
            await navigate("/verify-account", { state: response?.data })
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };





    return (
        <>
            <section class="authentication-bg">
                <div class="container py-5 h-100">
                    <div class="row d-flex justify-content-center align-items-center h-100">
                        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div class="card shadow-2-strong border-0 rounded" >
                                <div class="card-body p-5">

                                <img onClick={() => navigate("/")} className="mx-auto d-block auth-logo mb-5 cursor-pointer" width="400" src={`${process.env.REACT_APP_FRONTURL}assets/images/logo-new.svg`} />
                                    {/* <h3 className="font-size-20 text-center fw-semibold mb-3">Sign Up</h3> */}
                                    <form onSubmit={handleSubmit}>
                                        <div class="form-floating sp-input mb-3">
                                            <input type="text" class="form-control" id="floatingInput" placeholder="name"
                                                onChange={(e) => setName(e.target.value)} defaultValue={name} autocomplete="name" required />
                                            <label for="floatingInput">Your Full Name</label>
                                        </div>
                                        <div class="form-floating sp-input mb-3">
                                            <input disabled={id ? true : false} type="email" class="form-control" defaultValue={email} id="floatingInput" placeholder="name@example.com"
                                                onChange={(e) => setEmail(e.target.value)} autocomplete="email" required />
                                            <label for="floatingInput">Your Email Address</label>
                                        </div>
                                        <div class="form-floating sp-input mb-3">
                                            <input class="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => setPassword(e.target.value)}
                                                type={isSecureEntry ? "password" : "text"} autocomplete="password" required />
                                            <i onClick={() => { setisSecureEntry((prev) => !prev) }} className={`fa-solid ${isSecureEntry ? "fa-eye" : "fa-eye-slash"} eye-password`}></i>
                                            <label for="floatingPassword">Password</label>
                                        </div>
                                        <div class="form-floating sp-input mb-3">
                                            <input class="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => setconfirmPassword(e.target.value)}
                                                type={isSecureEntry1 ? "password" : "text"} autocomplete="password" required />
                                            <i onClick={() => { setisSecureEntry1((prev) => !prev) }} className={`fa-solid ${isSecureEntry1 ? "fa-eye" : "fa-eye-slash"} eye-password`}></i>
                                            <label for="floatingPassword">Confirm Password</label>
                                        </div>

                                        <div class="form-check sp-signup-checkbox mb-4">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={tc} onChange={() => settc(!tc)} />
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Accept all <a className="white-color link-underline"  >Terms & Conditions</a>
                                            </label>
                                        </div>
                                        {/* <div class="text-center">
                                            <button class="btn sp-blue-btn sp-btn btn-lg" type="submit">Sign Up</button>
                                        </div> */}
                                        <button class="btn sp-button sp-blue-button w-100" type="submit">Sign Up</button>

                                    </form>
                                </div>
                                <div class="card-footer sp-auth-card-footer">
                                    <div className="text-center">
                                        <a onClick={() => navigate("/login")} >
                                            Already have an account? <b className="light-blue-color">Sign in</b>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default SignUp;