import React, { useEffect, useState } from 'react'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBusiness, getVisitingForMember } from '../../store/slices/userSlice';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Download, Eye, Pencil, Search, X } from 'lucide-react';

const Visitings = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const business = useSelector(getBusiness)

    const [visitings, setvisitings] = useState()
    const [load, setload] = useState(false);



    const mailPackageList = async () => {
        try {
            setload(true);
            const response = await dispatch(getVisitingForMember(business?.businessId)).unwrap();

            setvisitings(response?.data)
            setload(false);

        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };


    useEffect(() => {
        let mount = true;
        if (mount) {
            mailPackageList();
        }
        return () => {
            mount = false;
        };
    }, [business])

    return (
        <>
        <div className="sp-customer-page pb-5 px-md-5 px-3">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb sp-breadcrumb">
                    <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{t('visitings')}</li>
                </ol>
            </nav>

            <div className="row gy-3 mb-4 align-items-center">
                <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
                    <img height="32" src="../assets/images/visiting-icon-sp.svg" className="icon-text" />
                    <h2 className="font-size-24 titilium-font fw-bold black-color mb-0">{t('visitings')}</h2>
                </div>
                <div className="col-md-6 order-md-2 order-3">
                </div>
                <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
                    <button class="btn sp-button sp-blue-button" onClick={() => navigate("/add-visiting")}>{t('add')} {t('visitor')}</button>
                </div>
            </div>

            <div className="sp-shadow sp-radius-20 white-bg">
                <div class="table-responsive">
                    <table class="table sp-table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">{t('type')}</th>
                                <th scope="col">{t('location')}</th>
                                <th scope="col">{t('visitDate')}</th>
                                <th scope="col">{t('purpose')}</th>
                                <th scope="col">{t('status')}</th>
                                <th style={{ width: '100px' }} scope="col">{t('actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {load ? new Array(6).fill(null).map((_, index) => (<tr><td colSpan={8}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) : visitings?.map((data, i) => (
                                <tr className='cursor-pointer'>

                                    <td style={{ 'verticalAlign': 'middle' }}>{data?.type}</td>
                                    <td style={{ 'verticalAlign': 'middle' }}>{data?.spaceId?.name}</td>
                                    <td style={{ 'verticalAlign': 'middle' }}>{moment(data?.dateTime).format("MMMM Do YYYY")}</td>
                                    <td style={{ 'verticalAlign': 'middle' }}>{data?.purpose}</td>
                                    {data.type == "Single" ?
                                        <td style={{ 'verticalAlign': 'middle' }}><span className={`btn sp-button py-2 ${data?.isCompleted == 1 ? "sp-green-button" : "sp-red-button"}`} >{data?.checkIn && data?.checkOut ? "Check Out" : data?.checkIn ? "Check In" : "Pending"}</span></td>
                                        :
                                        <td style={{ 'verticalAlign': 'middle' }}><span className={`btn sp-button py-2 ${data?.isCompleted == 1 ? "sp-green-button" : "sp-red-button"}`} >{data?.multiCheckIns?.length > 0 ? "Check In" : "Pending"}</span></td>
                                    }
                                    <td style={{ 'verticalAlign': 'middle' }}>
                                            <div className="d-flex align-items-center gap-2">
                                            <div onClick={() => navigate("/visiting-detail", { state: data })} className={`sp-action-icons`}><Eye width="18" /></div>
                                            {/* <div className={`sp-action-icons`}><Pencil width="18" /></div> */}
                                            <div  className={`sp-action-icons`}><X width="18" /></div>
                                            </div>
                                        </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </>
    )
}

export default Visitings