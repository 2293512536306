import toast from 'react-hot-toast';
import axios from "axios";
import { addPackage, approveDisapproveReport, updateScript, blockUnblock, dashboard, deleteAccount, updatePackage, deleteTemplate, getAllUsers, getReportedMeme, getPackages, recentMemes, signinUser, TcPp, updatePassword, updateTcpp, userLogout, getAllSounds, deleteSound, getAllCategories, deleteCategory, addCategory, createSound, signUp, verifyAccount, forgetPassword, changePassword, addBusiness, socialLogin, getByBusinessData, addSpace, addRoom, addDesk, addLead, addPlan, getPlans, getSpacesForDropDown, getRoomsAndDesksForDropDown, getPlansForBookingForDropDown, userList, getAllActivity, getAllReminder, createActivity, createReminder, reminderDelete, activityDelete, reminderEdit, activityEdit, getAllSpaces, getAllRooms, getAllDesks, addBooking, getInvoiceDetail, signOut, getBookings, getAllPlans, getInvoices, getBookingDetail, invoicePayment, downloadPdfSlice, listOfPlans, getPlanDetail, memberBooking, bookingDetail, getBookingList, availableSlots, checkDeskAvaibility, setupPassword, chatList, getUsersToStartChat, sendTheAttachment, addLikeAndUnlike, getAllFavortiePlans, getAllTechnicalCategory, checkLocations, addTicket, getMemberTickets, ticketDetail, addCommentOnTicket, closeTicket, reopenTicket, likeTheNews, getNewsDetail, checkHotDeskAvaibility, profileSetting, changePackageStatus, getPrintAndScan, addPrintScanRequest, deletePrintandScan, getVisitingForMember, updateVisitingStatus, addVisitingRequest, selectBusiness } from "../slices/userSlice"
export const extraReducers = (builder) => {
    builder

        // Sign Up
        .addCase(signUp.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(signUp.fulfilled, (state, action) => {
            state.status = 'succeeded'
            toast.success(action.payload.message)
            state.error = null
        })
        .addCase(signUp.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        // Verify Account
        .addCase(verifyAccount.pending, (state, action) => {

            state.status = "loading";
        })
        .addCase(verifyAccount.fulfilled, (state, action) => {
            state.status = "succeeded";
            toast.success(action?.payload?.message);



            localStorage.setItem("user", JSON.stringify(action.payload.data));
            state.user = action.payload.data;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${action.payload.data.token}`;

            state.error = null;

        })
        .addCase(verifyAccount.rejected, (state, action) => {
            state.status = "failed";
            state.error = action?.payload?.message;
            toast.error(action?.payload?.message);
        })

        // Sign In
        .addCase(signinUser.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(signinUser.fulfilled, (state, action) => {
            state.status = 'succeeded'
            toast.success(action.payload.message)
            localStorage.setItem("user", JSON.stringify(action.payload.data));
            localStorage.setItem("business", JSON.stringify(action.payload.business));
            state.user = action.payload.data
            state.business = action.payload.business
            axios.defaults.headers.common['Authorization'] = action.payload.data.token;
            state.error = null
        })
        .addCase(signinUser.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        .addCase(selectBusiness.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(selectBusiness.fulfilled, (state, action) => {
            state.status = 'succeeded'
            toast.success(action.payload.message)
            localStorage.setItem("user", JSON.stringify(action.payload.data));
            localStorage.setItem("business", JSON.stringify(action.payload.business));
            state.user = action.payload.data
            state.business = action.payload.business
            axios.defaults.headers.common['Authorization'] = action.payload.data.token;
            state.error = null
        })
        .addCase(selectBusiness.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // Sign In
        .addCase(getByBusinessData.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getByBusinessData.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.business = action?.payload?.data
            state.error = null
        })
        .addCase(getByBusinessData.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        // Sign In
        .addCase(userList.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(userList.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(userList.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message

        })
        .addCase(getAllTechnicalCategory.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getAllTechnicalCategory.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getAllTechnicalCategory.rejected, (state, action) => {
            state.status = 'failed'
        })

        .addCase(checkLocations.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(checkLocations.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(checkLocations.rejected, (state, action) => {
            state.status = 'failed'
        })

        .addCase(ticketDetail.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(ticketDetail.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null

        })
        .addCase(ticketDetail.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message

        })
        .addCase(addCommentOnTicket.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addCommentOnTicket.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)

        })
        .addCase(addCommentOnTicket.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message

        })
        .addCase(closeTicket.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(closeTicket.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)

        })
        .addCase(closeTicket.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message

        })
        .addCase(reopenTicket.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(reopenTicket.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)

        })
        .addCase(reopenTicket.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message

        })
        .addCase(likeTheNews.pending, (state, action) => {
            // state.status = 'loading'
        })
        .addCase(likeTheNews.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null


        })
        .addCase(likeTheNews.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message

        })
        .addCase(getNewsDetail.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getNewsDetail.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null

        })
        .addCase(getNewsDetail.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message

        })

        .addCase(getMemberTickets.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getMemberTickets.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getMemberTickets.rejected, (state, action) => {
            state.status = 'failed'
        })

        .addCase(addTicket.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addTicket.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addTicket.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        .addCase(listOfPlans.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(listOfPlans.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(listOfPlans.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message

        })
        .addCase(getPlanDetail.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getPlanDetail.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getPlanDetail.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message

        })

        .addCase(getAllSpaces.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getAllSpaces.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getAllSpaces.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        .addCase(getAllRooms.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getAllRooms.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getAllRooms.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        .addCase(getAllDesks.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getAllDesks.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getAllDesks.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message

        })
        // Sign In
        .addCase(getAllActivity.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getAllActivity.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getAllActivity.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            // toast.error(action.payload.message)
        })
        // Sign In
        .addCase(getAllReminder.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getAllReminder.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getAllReminder.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            // toast.error(action.payload.message)
        })
        // Sign In
        .addCase(createActivity.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(createActivity.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(createActivity.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        // Sign In
        .addCase(createReminder.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(createReminder.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(createReminder.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        .addCase(reminderEdit.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(reminderEdit.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(reminderEdit.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        .addCase(activityEdit.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(activityEdit.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(activityEdit.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        .addCase(reminderDelete.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(reminderDelete.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(reminderDelete.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        .addCase(activityDelete.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(activityDelete.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(activityDelete.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        .addCase(deletePrintandScan.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deletePrintandScan.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(deletePrintandScan.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        .addCase(setupPassword.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(setupPassword.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(setupPassword.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // Sign In
        .addCase(getPlans.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getPlans.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getPlans.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        .addCase(getVisitingForMember.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getVisitingForMember.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getVisitingForMember.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })


        .addCase(updateVisitingStatus.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updateVisitingStatus.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)

        })
        .addCase(updateVisitingStatus.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        .addCase(addVisitingRequest.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addVisitingRequest.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)

        })
        .addCase(addVisitingRequest.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        .addCase(getAllPlans.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getAllPlans.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getAllPlans.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message

        })

        .addCase(getBookings.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getBookings.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getBookings.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            // toast.error(action.payload.message)
        })
        .addCase(getInvoices.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getInvoices.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getInvoices.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            // toast.error(action.payload.message)
        })

        .addCase(getBookingDetail.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getBookingDetail.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getBookingDetail.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message

        })

        .addCase(getBookingList.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getBookingList.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getBookingList.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message


        })
        .addCase(availableSlots.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(availableSlots.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(availableSlots.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        .addCase(checkDeskAvaibility.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(checkDeskAvaibility.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(checkDeskAvaibility.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        .addCase(checkHotDeskAvaibility.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(checkHotDeskAvaibility.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(checkHotDeskAvaibility.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        .addCase(profileSetting.pending, (state, action) => {
            state.status = 'loading'

        })
        .addCase(profileSetting.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(profileSetting.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        .addCase(changePackageStatus.pending, (state, action) => {
            state.status = 'loading'

        })
        .addCase(changePackageStatus.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(changePackageStatus.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        .addCase(chatList.pending, (state, action) => {

        })
        .addCase(chatList.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(chatList.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        .addCase(getUsersToStartChat.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getUsersToStartChat.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getUsersToStartChat.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })
        .addCase(getPrintAndScan.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getPrintAndScan.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getPrintAndScan.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })


        .addCase(getSpacesForDropDown.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getSpacesForDropDown.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getSpacesForDropDown.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        .addCase(getRoomsAndDesksForDropDown.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getRoomsAndDesksForDropDown.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getRoomsAndDesksForDropDown.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        .addCase(getPlansForBookingForDropDown.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getPlansForBookingForDropDown.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getPlansForBookingForDropDown.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        .addCase(getInvoiceDetail.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getInvoiceDetail.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getInvoiceDetail.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        .addCase(signOut.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(signOut.fulfilled, (state, action) => {
            state.status = 'succeeded'
            localStorage.clear();
            state.error = null

            state.status = 'succeeded'
            state.user = null
            state.error = null
            state.TcPp = null
            state.dashboard = null
            state.lineChart = null
            state.areaChart = null

            toast.success(action.payload.message)

        })
        .addCase(signOut.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        //socialLogin
        .addCase(socialLogin.pending, (state, action) => {
            state.status = "loading";
        })
        .addCase(socialLogin.fulfilled, (state, action) => {
            state.status = "succeeded";
            localStorage.setItem("user", JSON.stringify(action.payload.data));
            toast.success(action?.payload?.message);
            state.user = action.payload.data
            axios.defaults.headers.common['Authorization'] = action.payload.data.token;
            state.error = null;
        })
        .addCase(socialLogin.rejected, (state, action) => {
            state.status = "failed";
            state.error = action?.payload?.message;
            toast.error(action?.payload?.message);
        })
        //forgetPassword
        .addCase(forgetPassword.pending, (state, action) => {
            state.status = "loading";
        })
        .addCase(forgetPassword.fulfilled, (state, action) => {
            state.status = "succeeded";
            toast.success(action?.payload?.message);
        })
        .addCase(forgetPassword.rejected, (state, action) => {
            state.status = "failed";
            state.error = action?.payload?.message;
            toast.error(action?.payload?.message);
        })

        //Change password
        .addCase(changePassword.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(changePassword.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(changePassword.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        //Add Business

        .addCase(addBusiness.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addBusiness.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addBusiness.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        //Add Space

        .addCase(addSpace.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addSpace.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addSpace.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        .addCase(addBooking.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addBooking.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addBooking.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        .addCase(memberBooking.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(memberBooking.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(memberBooking.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        .addCase(bookingDetail.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(bookingDetail.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null

        })
        .addCase(bookingDetail.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        .addCase(getAllFavortiePlans.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getAllFavortiePlans.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null

        })
        .addCase(getAllFavortiePlans.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        //Add Room

        .addCase(addRoom.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addRoom.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addRoom.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        .addCase(sendTheAttachment.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(sendTheAttachment.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null

        })
        .addCase(sendTheAttachment.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        .addCase(addLikeAndUnlike.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addLikeAndUnlike.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addLikeAndUnlike.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        //Add Desk

        .addCase(addDesk.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addDesk.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addDesk.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        //Add Desk

        .addCase(addLead.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addLead.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addLead.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        .addCase(downloadPdfSlice.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(downloadPdfSlice.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(downloadPdfSlice.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })


        .addCase(invoicePayment.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(invoicePayment.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(invoicePayment.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        //Add Desk

        .addCase(addPlan.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addPlan.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addPlan.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // Dashboard
        .addCase(dashboard.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(dashboard.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            state.dashboard = { usersCount: action.payload.users, sounds: action.payload.sounds }

        })
        .addCase(dashboard.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })


        // Recent Memes
        .addCase(recentMemes.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(recentMemes.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            state.memes = action.payload.memes
        })
        .addCase(recentMemes.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })


        // update Password
        .addCase(updatePassword.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updatePassword.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(updatePassword.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })


        // Get Users
        .addCase(getAllUsers.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getAllUsers.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null

        })
        .addCase(getAllUsers.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })



        // Get Sounds
        .addCase(getAllSounds.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getAllSounds.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null

        })
        .addCase(getAllSounds.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        // Get Category
        .addCase(getAllCategories.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getAllCategories.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null

        })
        .addCase(getAllCategories.rejected, (state, action) => {
            state.status = 'failed'
                (action.payload.message, "hello")
            state.error = action.payload.message
        })

        // Create Template
        .addCase(addPackage.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addPackage.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addPackage.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        .addCase(addPrintScanRequest.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addPrintScanRequest.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addPrintScanRequest.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // Create Category
        .addCase(addCategory.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addCategory.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(addCategory.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        // Create Category
        .addCase(createSound.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(createSound.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(createSound.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })


        // Get Templates
        .addCase(getPackages.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getPackages.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getPackages.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })


        // Delete Templates
        .addCase(deleteTemplate.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteTemplate.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(deleteTemplate.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })

        // Get Reported Memes
        .addCase(getReportedMeme.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getReportedMeme.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
        })
        .addCase(getReportedMeme.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        // Report Management
        .addCase(approveDisapproveReport.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(approveDisapproveReport.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(approveDisapproveReport.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

        // Delete Account
        .addCase(deleteAccount.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteAccount.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(deleteAccount.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        // Delete Sound
        .addCase(deleteSound.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteSound.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(deleteSound.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })
        // Delete Category
        .addCase(deleteCategory.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteCategory.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(deleteCategory.rejected, (state, action) => {
            state.status = 'failed'

            state.error = action.payload.message
            toast.error(action.payload.message)
        })


        // Block un Block
        .addCase(blockUnblock.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(blockUnblock.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            toast.success(action.payload.message)
        })
        .addCase(blockUnblock.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
            toast.error(action.payload.message)
        })


        // Log Out
        .addCase(userLogout.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(userLogout.fulfilled, (state, action) => {
            toast.success(action.payload.message)
            localStorage.clear();
            state.status = 'succeeded'
            state.user = null
            state.error = null
            state.TcPp = null
            state.dashboard = null
            state.lineChart = null
            state.areaChart = null
            state.memes = null
        })
        .addCase(userLogout.rejected, (state, action) => {
            toast.error(action.payload.message)
            state.status = 'failed'
            state.error = action.payload.message
        })


        // Get TcPp
        .addCase(TcPp.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(TcPp.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            state.TcPp = action.payload.tcAndPp
        })
        .addCase(TcPp.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })


        // Update TcPp
        .addCase(updateTcpp.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updateTcpp.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            state.TcPp = action.payload.tcAndPp
            toast.success(action.payload.message)
        })
        .addCase(updateTcpp.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })
        // Update Script
        .addCase(updateScript.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updateScript.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            state.TcPp = action.payload.tcAndPp
            toast.success(action.payload.message)
        })
        .addCase(updateScript.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })
        // Update Package
        .addCase(updatePackage.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updatePackage.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.error = null
            state.TcPp = action.payload.tcAndPp
            toast.success(action.payload.message)
        })
        .addCase(updatePackage.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload.message
        })

}