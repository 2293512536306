import React from 'react'

const CardSkeleton = ({ column }) => {
  return (
    <>
                <div className={`${column} sp-cards`}>
                <div className="sp-card-body">
                    <div className="sp-card-image w-100 shimmer" ></div>
                </div>
                <div className="sp-card-footer">
                    <div className="shimmer mb-1" style={{height:'14px', width:'100px'}}></div>
                    <div className="shimmer" style={{height:'18px', width:'180px'}}></div>
                </div>
            </div>
    </>
  )
}

export default CardSkeleton