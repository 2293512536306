import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { addTicket, checkLocations, getAllTechnicalCategory, getBusiness } from '../../store/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spinner from '../../components/Spinner';

const AddTicket = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const business = useSelector(getBusiness)

  const [description, setdescription] = useState("");
  const [category, setcategory] = useState("");
  const [priority, setpriority] = useState("");
  const [listOfCategory, setlistOfCategory] = useState([]);
  const [spaces, setspaces] = useState([]);
  const [spaceId, setspaceId] = useState("");
  const [image, setimage] = useState("");
  const [load, setload] = useState(false);


 

  const getTechnicalCategory = async () => {
    try {
      const response = await dispatch(getAllTechnicalCategory(business?.businessId)).unwrap();
      setlistOfCategory(response?.data);

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const getLocationsFoTicket = async () => {
    try {
      const response = await dispatch(checkLocations(business?.businessId)).unwrap();
      setspaces(response?.data);

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const handleSubmitForTicket = async (e) => {
    e.preventDefault();
    try {
      setload(true);
      const form = new FormData();

      form.append("description", description);
      form.append("priority", priority);
      form.append("category", category);
      form.append("spaceId", spaceId ? spaceId : spaces[0]._id);
      form.append("businessId", business?.businessId);
      form.append("ticketImage", image);



      await dispatch(addTicket(form)).unwrap();

      setdescription("")
      setpriority("")
      setcategory("")
      setspaceId("")
      setimage(null)
      navigate("/tickets")
      
      setload(false);

    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {

    getTechnicalCategory();
    getLocationsFoTicket()

  }, [business])


  return (
    <>
      {load ? <Spinner /> : <></>}
      <div className="sp-add-ticket-page pb-5 px-md-5 px-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb sp-breadcrumb">
            <li class="breadcrumb-item"><a>{t('menu')}</a></li>
            <li class="breadcrumb-item"><a onClick={() => navigate("/tickets")}>{t('supportTickets')}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{t('add')} {t('supportTickets')}t</li>
          </ol>
        </nav>
        <div className="d-flex align-items-center gap-2 mb-4">
          <img height="32" src="./assets/images/support-ticket-icon-sp.svg" className="icon-text" />
          <h2 className="font-size-24 titilium-font fw-bold black-color mb-0">{t('add')} {t('supportTickets')}</h2>
        </div>
        <div className="row gy-3 mb-4">
          <div className="col-md-6">
            <div class="dropdown sp-select-dropdown mb-3">
              <a class="dropdown-toggle text-capitalize" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {category ? listOfCategory.find(data => data._id === category)?.name : <>{t('category')}</>}
              </a>
              <ul class="dropdown-menu">
                {listOfCategory?.map((data, i) => (
                  <li key={i} onClick={() => setcategory(data?._id)}><a class="dropdown-item" >{data?.name}</a></li>
                ))}
              </ul>
            </div>
            <div class="dropdown sp-select-dropdown mb-3">
              <a class="dropdown-toggle text-capitalize" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {spaceId ? spaces.find(data => data._id === spaceId)?.name : <>{t('location')}</>}
              </a>
              <ul class="dropdown-menu">
                {spaces?.map((data, i) => (
                  <li key={i} onClick={() => setspaceId(data?._id)}><a class="dropdown-item" >{data?.name}</a></li>
                ))}
              </ul>
            </div>
            <div class="dropdown sp-select-dropdown mb-3">
              <a class="dropdown-toggle text-capitalize" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {priority ? priority : <>{t('priority')}</>}
              </a>
              <ul class="dropdown-menu">
                <li onClick={() => setpriority("Low")}><a class="dropdown-item">Low</a></li>
                <li onClick={() => setpriority("Medium")}><a class="dropdown-item">Medium</a></li>
                <li onClick={() => setpriority("High")}><a class="dropdown-item">High</a></li>
              </ul>
            </div>

          </div>
          <div className="col-md-6">
            <div class="form-floating sp-textarea mb-3">
              <textarea class="form-control" placeholder="Leave a comment here" id="Description" style={{ height: '200px' }} value={description} onChange={(e) => setdescription(e.target.value)}></textarea>
              <label for="Description">{t('description')}</label>
            </div>
            <div class="form-floating sp-input mb-3">
              <input type="file" class="form-control" id="Ticket-Proof" placeholder="image" onChange={(e) => setimage(e.target.files[0])} />
              <label for="Ticket-Proof">{t('ticketProof')}</label>
            </div>
          </div>
        </div>

        <div className="text-center">
          <button class="btn sp-button sp-blue-button" onClick={handleSubmitForTicket}>{t('save')}</button>
        </div>
      </div>
    </>
  )
}

export default AddTicket