import React, { useEffect, useState } from 'react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Heart } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { addLikeAndUnlike, getBusiness, listOfPlans } from '../../store/slices/userSlice';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CardSkeleton from '../../components/CardSkeleton';
import { useTranslation } from 'react-i18next';



const Plans = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const location = useLocation()
    const business = useSelector(getBusiness);

    const [plans, setplans] = useState()
    const [filterPlan, setfilterPlan] = useState()
    const [type, settype] = useState(location.state)
    const [load, setload] = useState(false);

    const getAllPlans = async () => {
        try {
            setload(true);
            const response = await dispatch(listOfPlans(business?.businessId)).unwrap();
            setplans(response?.data)
            setfilterPlan(response?.data)
            setload(false);
        } catch (rejectedValueOrSerializedError) {
            setload(false);
            console.log(rejectedValueOrSerializedError);
        }
    };
    console.log(type)
    const likeUnLikePlan = async (data) => {
        try {

            let payload = {
                planId: data._id,
                behave: data.behave

            }
            await dispatch(addLikeAndUnlike(payload)).unwrap();
            getAllPlans()
        } catch (rejectedValueOrSerializedError) {
            console.log(rejectedValueOrSerializedError);
        }
    };

    const handleRadioChange = (event) => {
        const selectedType = event.target.value;
        settype(selectedType);
    };

    useEffect(() => {
        let mount = true;
        if (mount) {
            getAllPlans();
        }
        return () => {
            mount = false;
        };
    }, [business])

    useEffect(() => {
        if (type) {
            if (type == "All") {
                setfilterPlan(plans)
            } else {

                let arr = plans?.filter((data) => data?.type === type)
                setfilterPlan(arr)
            }
        }

    }, [type, plans])

    console.log(filterPlan)
    const BookByService = [
        {
            id: 11,
            name: 'All',
            image: 'Anything_Else_icon.png'
        },
        {
            id: 1,
            name: 'Virtual Office',
            image: 'Virtual_Office_icon.png'
        },
        {
            id: 2,
            name: 'Hot Desk',
            image: 'Hot_Desk_icon.png'
        },
        {
            id: 3,
            name: 'Dedicated Desk',
            image: 'Dedicated_Desk_icon.png'
        },
        {
            id: 4,
            name: 'Executive Cabin',
            image: 'Executive_Cabin_icon.png'
        },
        {
            id: 5,
            name: 'Shared Room',
            image: 'Shared_Room_icon.png'
        },
        {
            id: 6,
            name: 'Private Room',
            image: 'Private_Room_icon.png'
        },
        {
            id: 7,
            name: 'Meeting Room',
            image: 'Meeting_Room_icon.png'
        },
        {
            id: 8,
            name: 'Podcast Room',
            image: 'Podcast_Room_icon.png'
        },
        {
            id: 9,
            name: 'Huddle Room',
            image: 'Huddle_Room-icon.png'
        },
        {
            id: 10,
            name: 'Event Space',
            image: 'Event_Space_icon.png'
        },
      


    ]


    return (
        <>
        <div className="sp-bookings-page pb-5 px-md-5 px-3">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb sp-breadcrumb">
                    <li class="breadcrumb-item"><a>{t('menu')}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{t('locations')}</li>
                </ol>
            </nav>
            <div className="d-flex align-items-center gap-2 mb-4">
            <img height="32" src="./assets/images/locations-icon-sp.svg" className="icon-text" />
            <h2 className="font-size-24 titilium-font fw-bold black-color mb-0">{t('locations')}</h2>
            </div>

            <div className="sp-shadow sp-radius-20 white-bg p-3">
            <div className="mb-3">
            <Swiper
            modules={[Navigation, A11y]}
            spaceBetween={50}
            //   slidesPerView={3}
            navigation
            breakpoints={{
                468: {
                    slidesPerView: 3,
                },
                768: {
                    slidesPerView: 6,
                },
                1080: {
                    slidesPerView: 11,
                },
            }}>
            {BookByService?.map((data) => (
                <SwiperSlide>
                    <div className="sp-filter-select">
                        <input type="radio" name="filter-select" class="btn-check" id={`${data.id}-select`} value={data.name}
                            checked={type === data.name} onChange={handleRadioChange} onClick={() => settype(data.name)} />
                        <label class="btn d-flex flex-column align-items-center justify-content-between gap-2" for={`${data.id}-select`}>
                            <img src={`../assets/images/${data?.image}`} alt="" />
                            <p className="font-size-12 mb-0" >{data.name}</p></label>
                    </div>
                </SwiperSlide>
            ))}
            </Swiper>
            </div>

        <div className="row gy-4">
        {load ? new Array(8).fill(null).map((_, index) => (<CardSkeleton key={index} column="col-md-3" />)) : filterPlan?.map((data, index) => (
            <div className="col-md-3 sp-cards" key={index} >
                <div className="sp-card-body">
                    <a onClick={() => navigate("/plan-detail", { state: data })}>
                    <img alt="" src={`${process.env.REACT_APP_IMGURL}/${data?.image[0]}`} className="sp-card-image w-100" />
                    </a>
                    <div className="d-flex justify-content-between align-items-center top-box">
                        <span className="font-size-14 py-1 px-3 text-capitalize white-color fw-medium" style={{ backgroundColor: 'var(--color5)' }}>{data?.type}</span>
                        {data?.isLiked ? <Heart className="cursor-pointer" fill="var(--color5" stroke="#FFFFFF" onClick={() => likeUnLikePlan(data)} /> : <Heart className="cursor-pointer" fill="#3131314D" stroke="#ffffff" onClick={() => likeUnLikePlan(data)} />}
                    </div>
                </div>
                <div className="sp-card-footer" >
                    <p className="font-size-16 mb-0 titilium-font black-color">{data?.spaceDetail?.name}</p>
                    <p className="font-size-18  mb-0 fw-semibold"><a className="light-blue-color" onClick={() => navigate("/plan-detail", { state: data })}>{data?.name}</a></p>
                </div>
            </div>
        ))}
    </div>
    </div>
        </div>
        </>
    )
}

export default Plans