import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getProfile, getUserToken, signOut } from '../../store/slices/userSlice'

const Header = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const authToken = useSelector(getUserToken)
  const profile = useSelector(getProfile)

  const handleSubmit = async () => {
    try {

      await dispatch(signOut()).unwrap();



    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  return (
    <>
      <header className="sm-header py-2">
        <nav class="navbar navbar-expand-lg">
          <div class="container">
            <a class="navbar-brand" href="/"><img className="cursor-pointer" width="240" alt="Spaces Manager" src={`../assets/images/logo-white.svg`} /></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end gap-4" id="navbarSupportedContent">
              <p className="font-size-14 mb-0 white-color titilium-font">Welcome {profile?.name}</p>
              {/* <p className="mb-0 position-relative"><i class="bi bi-cart3 white-color font-size-20"></i><span class="font-size-10 position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">9</span></p> */}
              <a href="https://spacesmanager.com/"><i class="bi bi-globe2 white-color font-size-20"></i></a>

              <div class="dropdown sm-profile-dropdown">
                <a className="profile-btn" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bi bi-list grey-color font-size-20 d-flex"></i>
                  <i class="bi bi-person light-blue-color font-size-20 d-flex"></i>
                  {/* <img src="https://via.placeholder.com/40x40" alt="" className="rounded-circle sm-profile-image-sm" /> */}
                </a>
                <div class="dropdown-menu">
                  <div className="d-flex gap-2 align-items-center px-3">
                    {profile?.imageName ? <img src={`${process.env.REACT_APP_IMGURL}/${profile?.imageName}`} alt="" className="rounded-circle sm-profile-image" /> : <img src="https://via.placeholder.com/80x80" alt="" className="rounded-circle sm-profile-image" />}
                    <p className="font-size-16 mb-0 light-blue-color titilium-font fw-semibold">{profile?.name}</p>
                  </div>
                  <div className="border-top mt-2 mb-2 mx-3"></div>
                  <ul className="sm-profile-dropdown-list">
                    <li><a class="dropdown-item" onClick={() => navigate("/messages")}><img src={`../assets/images/messages-icon-sm.svg`} alt="" width="" />Messages</a></li>
                    <li><a class="dropdown-item" onClick={() => navigate("/bookings")}><img src={`../assets/images/bookings-icon-sm.svg`} alt="" width="" />Bookings</a></li>
                    <li><a class="dropdown-item" onClick={() => navigate("/locations")}><img src={`../assets/images/bookings-icon-sm.svg`} alt="" width="" />Locations</a></li>
                    <li><a class="dropdown-item" onClick={() => navigate("/favorite-plans")}><img src={`../assets/images/favourites-icon-sm.svg`} alt="" width="" />Favourite</a></li>
                    <li><a class="dropdown-item" onClick={() => navigate("/tickets")}><img src={`../assets/images/messages-icon-sm.svg`} alt="" width="" /> Tickets</a></li>
                    <li><a class="dropdown-item" onClick={() => navigate("/print-and-scan")}><img src={`../assets/images/messages-icon-sm.svg`} alt="" width="" />Print and Scan</a></li>
                    <li><a class="dropdown-item" onClick={() => navigate("/mail-and-packages")}><img src={`../assets/images/messages-icon-sm.svg`} alt="" width="" />Packages</a></li>
                    <li><a class="dropdown-item" onClick={() => navigate("/visitings")}><img src={`../assets/images/messages-icon-sm.svg`} alt="" width="" />Visitings</a></li>
                    <li><a class="dropdown-item" onClick={() => navigate("/profile-setting")}><img src={`../assets/images/settings-icon-sm.svg`} alt="" width="" />Settings</a></li>
                    <li><a class="dropdown-item" onClick={handleSubmit}><img src={`../assets/images/log-out-icon-sm.svg`} alt="" width="" />Log out</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}

export default Header